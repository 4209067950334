/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Cookies from 'js-cookie';

const TOKEN_NAME = 'token';
const THERMAL_TOKEN_NAME = 'thermalPrinterToken';
const STORE_ID_KEY = 'storeId';
const MODULE_NAME = 'modules';

const setToken = (cvalue: string) => {
    if (!cvalue) {
        return;
    }
    Cookies.set(TOKEN_NAME, cvalue, { expires: 1 });
};

const setThermalPrinterToken = (cvalue: string) => {
    if (!cvalue) {
        return;
    }
    Cookies.set(THERMAL_TOKEN_NAME, cvalue, { expires: 1 });
};

const setModulePermissionList = (cvalue: string) => {
    if (!cvalue) {
        return;
    }
    localStorage.setItem(MODULE_NAME, JSON.stringify(cvalue));
};

const setStoreId = (cvalue: string) => {
    if (!cvalue) {
        return;
    }
    Cookies.set(STORE_ID_KEY, cvalue, { expires: 1 });
};

const getToken = (url = '') => Cookies.get(TOKEN_NAME);
const getThermalToken = (url = '') => Cookies.get(THERMAL_TOKEN_NAME);
const getStoreId = () => Cookies.get(STORE_ID_KEY);
const getModulePermission = () => localStorage.getItem(MODULE_NAME);

const deleteToken = () => {
    Cookies.remove(TOKEN_NAME);
    Cookies.remove(STORE_ID_KEY);
    localStorage.clear();
};

export {
    setToken,
    setThermalPrinterToken,
    setStoreId,
    getToken,
    deleteToken,
    getThermalToken,
    getStoreId,
    setModulePermissionList,
    getModulePermission,
};
