/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, Row } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { updateServiceDeleteAction, updateServiceLevelAction } from '../../pages/actions';
import callApi from '../../Api/config';
import { SERVICE_LEVEL_ENTITY } from '../../constants/EntityName';
import CustomTextEditor from '../CustomTextEditor';

import './style.scss';
import { toastError } from '../../service/Toast';

interface ServiceAddLevelProps {
    onAddLevel: (d1: any, d2: any) => void;
    onEditMode?: any;
    index?: number;
}

const ServiceAddLevel: React.FC<ServiceAddLevelProps> = ({ onAddLevel, onEditMode, index }) => {
    const [checkedBoxes, setCheckedBoxes] = useState<string[]>([]);
    const [priceValue, setPriceValue] = useState('');
    const [discountPriceValue, setDiscountPriceValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [labelId, setLabelId] = useState('');
    const [checkedLabel, setCheckedLabel] = useState('');
    const [modalLevelData, setModalLevelData] = useState<any>([]);
    const [newCheckboxValue, setNewCheckboxValue] = useState<string>('');
    const [isModal, setIsModal] = useState<boolean>();

    const { globalReducer } = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const garageId = globalReducer?.serviceIdData?.garageId;
    const selectorLevelData = globalReducer.levelData;

    // fetch levels based on garageId and set them in add level modal
    const fetchLevelData = async () => {
        try {
            const res = await callApi(`${SERVICE_LEVEL_ENTITY}/${garageId}`, 'GET');
            const addLevelData = res?.data?.data.map((item: any) => ({ title: item?.title, id: item?.id }));
            setModalLevelData(addLevelData);
        } catch (error) {
            console.error(`Error fetching level data for garage ${garageId}:`, error);
        }
    };

    // when in edit mode set present data in the fields
    const callWhenEdit = () => {
        let DATA = modalLevelData;
        if (onEditMode) {
            DATA = selectorLevelData
                .filter((item: any) => item.labelId === onEditMode)
                .map((item: any) => ({ title: item.label, id: item.labelId }));
            const editFieldValue = selectorLevelData.find((item: any) => item.labelId === onEditMode);
            setPriceValue(editFieldValue.priceValue);
            setDiscountPriceValue(editFieldValue.discountPriceValue);
            setDescriptionValue(editFieldValue.descriptionValue);
        }
        setModalLevelData(DATA);
    };

    // render levels data whenever garageId changes
    useEffect(() => {
        fetchLevelData();
    }, [garageId]);

    // render levels data based on level id when in edit mode
    useEffect(() => {
        callWhenEdit();
    }, [onEditMode]);

    // when check-box is clicked, expand and open the input-fields
    const handleCheckboxChange = (e: any, value: string) => {
        const valueArr = [...e.target.value.split('_')];
        setLabelId(valueArr[1]);
        const labelName = valueArr[0];
        setCheckedLabel(labelName);
        setCheckedBoxes((prevCheckedBoxes) => {
            if (prevCheckedBoxes.includes(labelName)) {
                return prevCheckedBoxes.filter((item) => item !== labelName);
            } else {
                return [labelName];
            }
        });
    };

    // filter-out level data from redux-store, that is deleted
    const handleDeleteLevel = (id: any) => {
        const filteredData = selectorLevelData.filter((item: any) => item.labelId !== id);
        dispatch(updateServiceDeleteAction(filteredData));
    };

    // save level data in the redux-store
    const handleAddButtonClick = () => {
        if (priceValue && discountPriceValue) {
            if (onEditMode) {
                handleDeleteLevel(onEditMode);
            }
            const selectLabelValue = {
                priceValue: priceValue,
                discountPriceValue: discountPriceValue,
                descriptionValue: descriptionValue,
                label: checkedLabel,
                labelId: labelId,
                index: index,
            };

            dispatch(updateServiceLevelAction([selectLabelValue]));
            setIsModal(false);

            onAddLevel(true, isModal);

            setPriceValue('');
            setDiscountPriceValue('');
            setDescriptionValue('');
        } else {
            toastError('Please enter data in fields');
        }
    };

    // hit POST api when adding new Label
    const handleAddCheckbox = () => {
        const postData = {
            title: newCheckboxValue,
            garage: {
                id: garageId,
            },
        };

        const postNewCheckData = async () => {
            try {
                const res = await callApi(SERVICE_LEVEL_ENTITY, 'POST', postData);
                const addLevelData = res?.data?.data?.map((item: any) => ({ title: item?.title, id: item?.id }));
                setModalLevelData(addLevelData);
            } catch (error) {
                console.error('Error posting new check data:', error);
            }
        };

        postNewCheckData();
    };

    const handleSetValue = (e: any) => {
        setDescriptionValue(e);
    };

    return (
        <div>
            <div>
                <h2 style={{ fontWeight: 'bold' }}>Add Levels</h2>
                <p>Add Levels to Scheduled Packages Sub-Category of Services</p>
            </div>
            <hr />
            <div className='selectAddContainer'>
                <h2>Select/add your Levels</h2>
                <div className='checkboxContainer'>
                    {modalLevelData?.map((item: any, index: number) => (
                        <Row key={index}>
                            <Col span={16}>
                                <Checkbox
                                    value={item.title + '_' + item.id}
                                    onChange={(e) => handleCheckboxChange(e, `checkbox${index}`)}
                                    checked={checkedBoxes.includes(item.title)}>
                                    {item.title}
                                </Checkbox>
                                <div className='accordianInput'>
                                    {checkedBoxes.includes(item.title) && (
                                        <>
                                            <Input
                                                placeholder='Enter Price'
                                                style={{ margin: '5px 0' }}
                                                value={priceValue}
                                                onChange={(e) => setPriceValue(e.target.value)}
                                                required
                                            />
                                            <Input
                                                placeholder='Enter Discount Price QAR'
                                                style={{ margin: '5px 0' }}
                                                value={discountPriceValue}
                                                onChange={(e) => setDiscountPriceValue(e.target.value)}
                                                required
                                            />
                                            <div className='textEditor'>
                                                <CustomTextEditor value={descriptionValue} setValue={handleSetValue} />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    ))}

                    <Row>
                        <Col span={24}>
                            <div className='newCheck'>
                                <input
                                    className='newCheckbox'
                                    name='newCheckbox'
                                    type='text'
                                    placeholder='Enter Manually'
                                    value={newCheckboxValue}
                                    onChange={(e) => setNewCheckboxValue(e.target.value)}
                                />
                                <span className='arrowIcon'>
                                    <ArrowRightOutlined onClick={handleAddCheckbox} />
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='addLevelBtn'>
                <Button id='btnAssign' onClick={handleAddButtonClick}>
                    Add
                </Button>
            </div>
        </div>
    );
};

export default ServiceAddLevel;
