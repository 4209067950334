import { useEffect, useState } from 'react';

import { Typography } from 'antd';
import { Card, Col, Row } from 'antd';
import { Table } from 'antd';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import { Bar } from 'react-chartjs-2';

import TotalRevenueImage from '../../assets/png/total-revenue.png';
import OrderCompleted from '../../assets/png/order-completed.png';
import OrderReceived from '../../assets/png/order-received.png';
import OrderPending from '../../assets/png/order-pending.png';

import callApi from '../../Api/config';
import { DASHBOARD } from '../../constants/EntityName';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

import './style.scss';
import LevelEarningChart from './LevelEarningChart';
import { toastWarning } from '../../service/Toast';

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState<any>();
    const [, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await callApi(`${DASHBOARD}`, 'GET');
                setDashboardData(res?.data?.data);
            } catch (error: any) {
                toastWarning(error);
            } finally {
                setError('API call is failed');
            }
        };
        fetchData();
    }, []);

    const monthNames = dashboardData?.yearlyData?.map((data: any) => data?.month);
    const dataBooking = dashboardData?.yearlyData?.map((data: any) => data?.bookingCount);

    const levelName = dashboardData?.top5PerformingServiceDetails?.map((data: any) => data?.level);
    const totalAmount = dashboardData?.top5PerformingServiceDetails?.map((data: any) => data?.totalAmount);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Earning Details',
            },
        },
        scales: {
            y: {
                min: 100, // Set the minimum value for the y-axis
                max: 5000, // Set the maximum value for the y-axis
            },
        },
    };

    const labels = monthNames;
    const randomColors = dataBooking?.map(
        () => `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.9)`,
    );

    const data = {
        labels,
        datasets: [
            {
                label: 'Earning Details',
                data: dataBooking,
                backgroundColor: randomColors,
                borderRadius: 15,
                barThickness: 15,
            },
        ],
    };

    // Assuming garageData is an array of objects
    const garageData = dashboardData?.topPerformingGarages?.map((garage: any) => ({
        ...garage,
        // If any field is empty, replace it with 'NA'
        garageName: garage.garageName || 'NA',
        contactPersonEmail: garage.contactPersonEmail || 'NA',
        contactPersonPhone: garage.contactPersonPhone || 'NA',
        address: garage.address || 'NA',
        totalAmount: garage.totalAmount || 'NA',
    }));

    const columns = [
        {
            title: 'Garage Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Garage Name',
            dataIndex: 'garageName',
            key: 'garageName',
        },
        {
            title: 'Contact Email',
            dataIndex: 'contactPersonEmail',
            key: 'Contact Email',
        },
        {
            title: 'Contact Phone',
            dataIndex: 'contactPersonPhone',
            key: 'Contact Phone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Payment (QAR)',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
    ];

    return (
        <div className='mainContainer'>
            <Typography.Title level={1} style={{ color: 'black', padding: '25px' }}>
                Dashboard Overview
            </Typography.Title>
            <Row gutter={16} justify={'center'}>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Card bordered={true} className='cardContainer'>
                        <div style={{ display: 'flex', columnGap: '10px' }}>
                            <img src={TotalRevenueImage} alt='Revenue Logo' width={40} height={40} />
                            <div>
                                <Typography.Title
                                    level={2}
                                    style={{ fontSize: '16px', fontWeight: '500', color: 'grey' }}>
                                    Total Revenue (QTR)
                                </Typography.Title>
                                <Typography.Text style={{ fontSize: '24px', fontWeight: '500', color: 'black' }}>
                                    {dashboardData?.totalRevenue}
                                </Typography.Text>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Card bordered={true} className='cardContainer'>
                        <div style={{ display: 'flex', columnGap: '10px' }}>
                            <img src={OrderReceived} alt='Order Received Logo' width={40} height={40} />
                            <div>
                                <Typography.Title
                                    level={2}
                                    style={{ fontSize: '16px', fontWeight: '500', color: 'grey' }}>
                                    Order Received
                                </Typography.Title>
                                <Typography.Text style={{ fontSize: '24px', fontWeight: '500', color: 'black' }}>
                                    {dashboardData?.receivedOrdersCount}
                                </Typography.Text>
                            </div>
                        </div>{' '}
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Card bordered={true} className='cardContainer'>
                        <div style={{ display: 'flex', columnGap: '10px' }}>
                            <img src={OrderCompleted} alt='Order Completed Logo' width={40} height={40} />
                            <div>
                                <Typography.Title
                                    level={2}
                                    style={{ fontSize: '16px', fontWeight: '500', color: 'grey' }}>
                                    Order Completed
                                </Typography.Title>
                                <Typography.Text style={{ fontSize: '24px', fontWeight: '500', color: 'black' }}>
                                    {dashboardData?.completedOrdersCount}
                                </Typography.Text>
                            </div>
                        </div>{' '}
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Card bordered={true} className='cardContainer'>
                        <div style={{ display: 'flex', columnGap: '10px' }}>
                            <img src={OrderPending} alt='Order Pending Logo' width={40} height={40} />
                            <div>
                                <Typography.Title
                                    level={2}
                                    style={{ fontSize: '16px', fontWeight: '500', color: 'grey' }}>
                                    Order Pending
                                </Typography.Title>
                                <Typography.Text style={{ fontSize: '24px', fontWeight: '500', color: 'black' }}>
                                    {dashboardData?.pendingOrdersCount}
                                </Typography.Text>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Typography style={{ marginTop: '20px', color: 'grey', paddingLeft: '25px' }}>
                The above data is from the past 30 days.
            </Typography>

            <div className='barChart'>
                <div className='yearlyChart'>
                    <Typography.Title level={3} style={{ color: 'black' }}>
                        Earning Details
                    </Typography.Title>
                    <Typography style={{ marginBottom: '15px', color: 'grey' }}>
                        The below data is from the past 12 Months
                    </Typography>
                    <Bar options={options} data={data} />
                </div>
                <div className='levelEarning'>
                    <LevelEarningChart levelName={levelName} totalAmount={totalAmount} />
                </div>
            </div>
            <div className='topPerformingGarage'>
                <Typography.Title level={3} style={{ color: 'black' }}>
                    Top Performing Garages
                </Typography.Title>
                <Table columns={columns} dataSource={garageData} className='tableList' pagination={false} />
            </div>
        </div>
    );
};

export default Dashboard;
