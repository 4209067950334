// import { fuelTypeOption } from '../constants/AppConstant';
import { acceptableImageFormat } from '../constants/FilterDataValues';
import { errorMsg } from '../constants/messages';

const ModelsFormData = [
    {
        title: 'Models Details',
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'make',
        label: 'Make',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Make',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Make',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'titleName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'fuelType',
        label: 'Fuel Type',
        fieldType: {
            name: 'multiSelect',
            options: [
                { name: 'Diesel', value: 'diesel' },
                { name: 'Petrol', value: 'petrol' },
                { name: 'Electric', value: 'electric' },
                { name: 'CNG', value: 'cng' },
                { name: 'Hydrogen', value: 'hydrogen' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Fuel Type', columnType: 'FuelTypeName' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'imageUrl',
        label: 'Icon Image',
        fieldType: {
            name: 'uploadFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { ModelsFormData };
