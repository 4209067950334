import { Form, Input } from 'antd';

import { InputInterface } from '../utils/forms/FormInterface';

import './style.scss';

interface EmailProps {
    formInput: InputInterface;
    index: number;
}

const Email = (props: EmailProps) => {
    const { formInput, index } = props;

    return (
        <Form.Item
            key={index}
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            labelAlign='left'
            name={formInput.name}
            rules={[
                {
                    type: 'email',
                    message: 'Please enter a valid E-mail!',
                },
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
            ]}>
            <Input
                placeholder={formInput.placeholder}
                type={formInput.fieldType.subfieldType}
                disabled={formInput.readonly}
            />
        </Form.Item>
    );
};

export default Email;
