import { Button, Modal } from 'antd';
import { Form, Input, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';

import callApi from '../Api/config';
import { ORDER_LIST_ENTITY } from '../constants/EntityName';
import { toastWarning } from '../service/Toast';

interface DeleteModalProps {
    visible: boolean;
    record: any;
    onCancel: () => void;
    entityId?: string;
}

const sizeOptions = [{ id: '1', value: 'Decrease', name: 'Decrease' }];

const DeleteModal: React.FC<DeleteModalProps> = ({ visible, record, onCancel, entityId }) => {
    const [weightChange, setWeightChange] = useState<string>('Decrease');
    const [weight, setWeight] = useState<any>(record.variant?.weight || 0);
    const [unitsOrdered, setUnitsOrdered] = useState<number>(0);
    const [formReset, setFormReset] = useState(false);

    useEffect(() => {
        if (formReset) {
            setWeightChange('');
            setWeight(record?.variant?.weight || 0);
            setUnitsOrdered(record?.unitsOrdered || 0);
            setFormReset(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formReset]);

    const refreshPage = () => {
        window.location.reload();
    };

    const getApiData = async () => {
        const payload = record.slug
            ? {
                  addons: {
                      slug: record.slug,
                      variantSlug: record.variantSlug,
                      unitsOrdered,
                  },
              }
            : {
                  variant: {
                      slug: record.variantSlug,
                      weight: 0,
                      weightChange: 'Decrease',
                  },
              };
        const results = await callApi(`${ORDER_LIST_ENTITY}/${entityId}/modify`, 'PATCH', payload);

        if (results?.data?.isSuccess) {
            setFormReset(true);
            refreshPage();
        } else {
            setFormReset(true);
            toastWarning(results?.data?.error);
        }
    };

    const handleOk = async () => {
        await getApiData();
        onCancel();
    };

    return (
        <Modal
            key={formReset ? 'reset' : 'normal'}
            title={`Edit Record Order ${entityId}`}
            visible={visible}
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
                <Button key='cancel' onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key='submit' type='primary' onClick={handleOk}>
                    Confirm Delete
                </Button>,
            ]}>
            <Form layout='vertical'>
                <Form.Item label='Display Name'>
                    <Input type='text' value={record.itemDisplayName ?? record.displayName} disabled />
                </Form.Item>

                <Form.Item label='Slug'>
                    <Input type='text' value={record.variantSlug ?? record.slug} disabled />
                </Form.Item>

                {!record.slug && (
                    <Form.Item label='Weight'>
                        <InputNumber min={1} value={weight} onChange={setWeight} disabled />
                    </Form.Item>
                )}

                {record.slug && (
                    <Form.Item label='Units Ordered'>
                        <Input
                            type='text'
                            value={unitsOrdered}
                            onChange={(e) => setUnitsOrdered(Number(e.target.value))}
                            disabled
                        />
                    </Form.Item>
                )}

                {!record.slug && (
                    <Form.Item label='Weight Change' required>
                        <Select
                            showSearch
                            placeholder='Select Weight Change'
                            allowClear
                            value={weightChange}
                            disabled
                            onChange={setWeightChange}>
                            {sizeOptions.map((option) => (
                                <Select.Option key={option.id} value={option.name}>
                                    {option.value}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

export default DeleteModal;
