// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; }

.garageModal {
  width: 400px;
  position: absolute;
  height: 140px;
  left: 40%;
  top: 30%;
  background-color: #e9e7e7;
  border: 1px solid #919090;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.modalCloseBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0; }

.commentCard {
  width: 90%; }

#commentBtn {
  display: block;
  margin: 10px;
  background-color: #0c0b0b;
  color: #fff; }
`, "",{"version":3,"sources":["webpack://./src/components/GarageModal/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa,EAAA;;AAGjB;EACI,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,QAAQ;EACR,yBAAoC;EACpC,yBAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;;AAGvB;EACI,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,oBAAoB,EAAA;;AAGxB;EACI,UAAU,EAAA;;AAGd;EACI,cAAc;EACd,YAAY;EACZ,yBAAiC;EACjC,WAAW,EAAA","sourcesContent":[".overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n  }  \n\n.garageModal {\n    width: 400px;\n    position: absolute;\n    height: 140px;\n    left: 40%;\n    top: 30%;\n    background-color: rgb(233, 231, 231);\n    border: 1px solid rgb(145, 144, 144);\n    border-radius: 5px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.modalCloseBtn{\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    padding: 10px 10px 0;\n}\n\n.commentCard{\n    width: 90%;\n}\n\n#commentBtn {\n    display: block;\n    margin: 10px;\n    background-color: rgb(12, 11, 11);\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
