// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bankContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  row-gap: 20px;
  border-radius: 4px;
  border: 1px solid #949393;
  padding: 30px;
  margin: auto;
  background-color: #eeeded; }
  .bankContainer .bankDetailHeading {
    font-size: 22px;
    font-weight: 600; }
  .bankContainer .bankDetailData {
    display: flex;
    justify-content: space-between; }
    .bankContainer .bankDetailData .bankLeftSpan {
      font-size: 20px;
      color: #747272; }
    .bankContainer .bankDetailData .bankRightSpan {
      font-size: 18px; }
`, "",{"version":3,"sources":["webpack://./src/components/BankDetails/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,WAAW;EAGX,aAAa;EACb,kBAAkB;EAClB,yBAAoC;EACpC,aAAa;EACb,YAAY;EACZ,yBAAoC,EAAA;EAZxC;IAeQ,eAAe;IACf,gBAAgB,EAAA;EAhBxB;IAoBQ,aAAa;IACb,8BAA8B,EAAA;IArBtC;MAwBY,eAAe;MACf,cAAyB,EAAA;IAzBrC;MA6BY,eAAe,EAAA","sourcesContent":[".bankContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 100%;\n    // max-width: 90%;\n    // min-height: 500px;\n    row-gap: 20px;\n    border-radius: 4px;\n    border: 1px solid rgb(148, 147, 147);\n    padding: 30px;\n    margin: auto;\n    background-color: rgb(238, 237, 237);\n\n    .bankDetailHeading {\n        font-size: 22px;\n        font-weight: 600;\n    }\n\n    .bankDetailData {\n        display: flex;\n        justify-content: space-between;\n\n        .bankLeftSpan {\n            font-size: 20px;\n            color: rgb(116, 114, 114);\n        }\n\n        .bankRightSpan {\n            font-size: 18px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
