import { Form, Input } from 'antd';

import { InputInterface } from '../utils/forms/FormInterface';

import './style.scss';

export interface InputProps {
    formInput: InputInterface;
    index: number;
}

const TextArea = (props: InputProps) => {
    const { formInput, index } = props;

    return (
        <Form.Item
            key={index}
            className='textArea'
            name={formInput.name}
            labelAlign='left'
            colon={formInput.required}
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
                ...((formInput.rules ? formInput.rules : []) as Array<any>),
            ]}>
            <Input.TextArea
                className='textAreaInput'
                placeholder={formInput.placeholder}
                disabled={formInput.readonly}
                value={[formInput.name]}
            />
        </Form.Item>
    );
};

export default TextArea;
