/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Button, Select } from 'antd';
import { useState } from 'react';

import './style.scss';

const { Option } = Select;

type FilterDataProps = {
    id?: string;
    handleStatusFilter?: ((values: any) => void) | undefined;
};

const FilterData: React.FC<FilterDataProps> = ({ id, handleStatusFilter }) => {
    const [selectedValue, setSelectedValue] = useState<any>(null);

    const handleWorkStatusChange = (values: any) => {
        setSelectedValue(values);
    };

    const handleApplyFilter = () => {
        if (handleStatusFilter) {
            handleStatusFilter(selectedValue);
        }
    };

    const handleClearFilter = () => {
        handleWorkStatusChange(undefined);
        if (handleStatusFilter) {
            handleStatusFilter(null);
        }
    };

    return (
        <div>
            <div>
                <h2 style={{ fontWeight: 'bold' }}>Filters</h2>
                <p style={{ color: 'gray' }}>
                    Fill out the <span style={{ fontWeight: '400', color: 'black' }}>Active Order List </span>by
                    selecting one or multiple filters
                </p>
            </div>

            <div className='commonStyle'>
                <h3>Status</h3>
                <Select
                    placeholder='Select Work Status'
                    style={{ width: '400px' }}
                    onChange={handleWorkStatusChange}
                    value={selectedValue}>
                    <Option value='pickup-scheduled'>Pickup Scheduled</Option>
                    <Option value='vehicle-in-transit'>Vehicle in Transit</Option>
                    <Option value='vehicle-reached'>Vehicle Reached</Option>
                </Select>
            </div>
            <div className='footer'>
                <Button id='footApplyBtn' className='filterModalBtn' onClick={handleApplyFilter}>
                    Apply
                </Button>
                <Button className='filterModalBtn' onClick={handleClearFilter}>
                    Reset
                </Button>
            </div>
        </div>
    );
};

export default FilterData;
