import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import './search.scss';
import debounce from '../../utils/debounce/Debounce';

interface SearchProps {
    // eslint-disable-next-line no-unused-vars
    setStateSearchQuery: (val: string) => void;
    placeholderValue?: string;
    leftIconImg?: boolean;
    rightIconImg?: boolean;
    onPressEnter?: () => void;
}

const SearchByState = (props: SearchProps) => {
    const {
        setStateSearchQuery: setStateSearchQuery,
        placeholderValue,
        leftIconImg,
        rightIconImg,
        onPressEnter,
    } = props;

    const onSearchChange = (value: string) => {
        setStateSearchQuery(value);
    };

    const onChange = debounce((e: any) => {
        onSearchChange(e.target.value);
    }, 500);

    const onPress = () => {
        if (onPressEnter) {
            onPressEnter();
        }
    };

    return (
        <div className='searchBoxContainer'>
            <Input
                prefix={leftIconImg ? <SearchOutlined className='searchOutline' /> : null}
                suffix={rightIconImg ? <SearchOutlined /> : null}
                onPressEnter={onPress}
                className='searchBox'
                onChange={onChange}
                placeholder={placeholderValue ? `Search by ${placeholderValue}` : 'Search...'}
            />
        </div>
    );
};

SearchByState.defaultProps = {
    leftIconImg: false,
    rightIconImg: false,
};

export default SearchByState;
