// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgCardContainer {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
  .imgCardContainer .titlePlusIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer; }
    .imgCardContainer .titlePlusIcon .cardTitle {
      font-size: 16px;
      font-weight: bold; }
    .imgCardContainer .titlePlusIcon .cardIcons span {
      padding: 5px; }
  .imgCardContainer .imageArea {
    display: flex;
    justify-content: center;
    align-items: center; }
`, "",{"version":3,"sources":["webpack://./src/components/GarageDetailCards/ImageCard/style.scss"],"names":[],"mappings":"AAAA;EACI,yBAAyB;EACzB,sBAAsB;EACtB,sCAAsC,EAAA;EAH1C;IAMQ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,eAAe,EAAA;IAVvB;MAaY,eAAe;MACf,iBAAiB,EAAA;IAd7B;MAmBgB,YAAY,EAAA;EAnB5B;IAyBQ,aAAa;IACb,uBAAuB;IACvB,mBAAmB,EAAA","sourcesContent":[".imgCardContainer {\n    background-color: #f0f0f0;\n    border: 1px solid #ddd;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);\n\n    .titlePlusIcon {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        padding: 10px;\n        cursor: pointer;\n\n        .cardTitle {\n            font-size: 16px;\n            font-weight: bold;\n        }\n\n        .cardIcons {\n            span {\n                padding: 5px;\n            }\n        }\n    }\n\n    .imageArea {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
