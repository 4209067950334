import { errorMsg } from '../constants/messages';
const commissionListingData = [
    {
        name: 'id',
        label: 'Id',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Id',
            allowAddRecordClick: true,
        },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'charge',
        label: 'Charge',
        fieldType: {
            name: 'number',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Charges',
            allowAddRecordClick: true,
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'currency',
        label: 'Currency',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Currency',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'commissionType',
        label: 'Commission Type',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Fixed', value: 'fixed' },
                { name: 'Percentage', value: 'percentage' },
            ],
        },
        placeholder: 'Click here to select an Commission Type',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Commission Type',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'commissionType',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];
export { commissionListingData };
