import { errorMsg } from '../constants/messages';

const pickUpFormData = [
    {
        name: 'bookingId',
        label: 'Booking Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Booking Id' },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'customerInfo',
        label: 'Customer Info',
        fieldType: {
            name: 'text',
        },
        required: false,
        tableMeta: {
            title: 'Customer Info',
        },
        errorMsg: errorMsg,
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'vehicleDetails',
        label: 'Vehicle Details',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Vehicle Details', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'pickupAddress',
        label: 'Pick Up Address',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Pick Up Address',
            allowAddRecordClick: false,
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: true,
        default: '',
    },
    {
        name: 'dropAddress',
        label: 'Drop Address',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Drop Address',
            allowAddRecordClick: false,
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: true,
        default: '',
    },
    {
        name: 'bookingDate',
        label: 'Booking Date',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Booking Date' },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'pickupDetails',
        label: 'Pickup Date & Time',
        fieldType: {
            name: 'text',
        },
        required: false,
        tableMeta: {
            title: 'Pickup Date & Time',
        },
        errorMsg: errorMsg,
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
        default: '',
    },
    {
        name: 'assignThirdParty',
        label: 'Actions',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Actions',
            allowAddRecordClick: 'true',
        },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
        default: '',
    },
];

export { pickUpFormData };
