// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldCardContainer {
  max-width: 400px;
  width: 100%;
  padding: 5px;
  margin-top: 20px; }
  .fieldCardContainer .titlePlusIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer; }
  .fieldCardContainer .fieldCardTitle {
    font-size: 16px;
    font-weight: bold; }
  .fieldCardContainer .inputArea {
    margin-top: 10px; }
    .fieldCardContainer .inputArea input {
      max-width: 400px;
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      border: 1px solid #b8b2b2;
      border-radius: 4px; }
`, "",{"version":3,"sources":["webpack://./src/components/GarageDetailCards/FieldCard/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,gBAAgB,EAAA;EAJpB;IAOQ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe,EAAA;EAVvB;IAcQ,eAAe;IACf,iBAAiB,EAAA;EAfzB;IAmBQ,gBAAgB,EAAA;IAnBxB;MAsBY,gBAAgB;MAChB,WAAW;MACX,YAAY;MACZ,sBAAsB;MACtB,yBAAyB;MACzB,kBAAkB,EAAA","sourcesContent":[".fieldCardContainer {\n    max-width: 400px;\n    width: 100%;\n    padding: 5px;\n    margin-top: 20px;\n\n    .titlePlusIcon {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        cursor: pointer;\n    }\n\n    .fieldCardTitle {\n        font-size: 16px;\n        font-weight: bold;\n    }\n\n    .inputArea {\n        margin-top: 10px;\n\n        input {\n            max-width: 400px;\n            width: 100%;\n            padding: 8px;\n            box-sizing: border-box;\n            border: 1px solid #b8b2b2;\n            border-radius: 4px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
