// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabLinks {
  display: flex;
  padding: 10px 20px; }
  .tabLinks p {
    padding: 10px;
    font-size: 1.3rem;
    text-decoration: none;
    transition: all 0.3s ease; }
    .tabLinks p:hover {
      cursor: pointer;
      border-bottom: 2px solid transparent; }
    .tabLinks p.active {
      font-weight: bold;
      border-bottom: 2px solid #000; }

.backToDashboard {
  display: flex;
  align-items: center;
  padding-left: 30px;
  cursor: pointer; }
  .backToDashboard p {
    margin: 0 0 0 5px; }

.tableTopHeader .headTitle h3 {
  padding: 15px 0px 0px 30px;
  font-size: 1.6rem;
  font-weight: 600; }

.tableTopHeader .headSubTitle {
  padding-left: 30px; }

.orderListBar {
  padding-left: 20px; }
  .orderListBar .actionbar {
    display: none; }

.tabLinks {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .tabLinks .links {
    display: flex;
    flex-direction: row; }
`, "",{"version":3,"sources":["webpack://./src/pages/OrderList/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,kBAAkB,EAAA;EAFtB;IAKQ,aAAa;IACb,iBAAiB;IACjB,qBAAqB;IACrB,yBAAyB,EAAA;IARjC;MAWY,eAAe;MACf,oCAAoC,EAAA;IAZhD;MAgBY,iBAAiB;MACjB,6BAA6B,EAAA;;AAKzC;EACI,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,eAAe,EAAA;EAJnB;IAOQ,iBAAiB,EAAA;;AAIzB;EAEQ,0BAA0B;EAC1B,iBAAiB;EACjB,gBAAgB,EAAA;;AAJxB;EAQQ,kBAAkB,EAAA;;AAI1B;EACI,kBAAkB,EAAA;EADtB;IAIQ,aAAa,EAAA;;AAIrB;EACI,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;EAHvB;IAMQ,aAAa;IACb,mBAAmB,EAAA","sourcesContent":[".tabLinks {\n    display: flex;\n    padding: 10px 20px;\n\n    p {\n        padding: 10px;\n        font-size: 1.3rem;\n        text-decoration: none;\n        transition: all 0.3s ease;\n\n        &:hover {\n            cursor: pointer;\n            border-bottom: 2px solid transparent;\n        }\n\n        &.active {\n            font-weight: bold;\n            border-bottom: 2px solid #000;\n        }\n    }\n}\n\n.backToDashboard{\n    display: flex;\n    align-items: center;\n    padding-left: 30px;\n    cursor: pointer;\n\n    p{\n        margin: 0 0 0 5px;\n    }\n}\n\n.tableTopHeader{\n    .headTitle h3{\n        padding: 15px 0px 0px 30px;\n        font-size: 1.6rem;\n        font-weight: 600;\n    }\n\n    .headSubTitle{\n        padding-left: 30px;\n    }\n} \n\n.orderListBar {\n    padding-left: 20px;\n\n    .actionbar{\n        display: none;\n    }\n}\n\n.tabLinks{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    .links{\n        display: flex;\n        flex-direction: row;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
