/* eslint-disable no-unused-vars */
import { FormInstance } from 'antd';

import { ORDER_LIST_ENTITY } from '../constants/EntityName';
import { PayloadFormatterKey } from './getDrawerFormOutgoingPayload';

type Arg = {
    setFormTemplate: any;
    incomingFormData: any;
    availableDeliveryPartners: any;
    entityId: string;
};

const getDisplaySlotName = (index: number) => `display-slot-${index + 1}`;
const getDisplaySlotTime = (index: number) => `display-slot-time-${index + 2}`;
const getDisplaySlotDate = (index: number) => `display-slot-Date-${index + 3}`;
const getDisplaySlotDeliveryStatus = (index: number) => `display-slot-Delivery-Status-${index + 4}`;

const getInputSlotName = (index: number) => `input-slot-${index + 1}`;

export const updateDatamodel = (arg: Arg) => {
    const { setFormTemplate, incomingFormData, availableDeliveryPartners, entityId } = arg;

    if (incomingFormData && availableDeliveryPartners) {
        setFormTemplate((formData: any[]) => [
            ...formData,
            ...incomingFormData.slots.reduce((acc: any, slot: any, index: number) => {
                const deliveryPartnerName = `${slot.deliveryPartner?.firstName} ${slot.deliveryPartner?.lastName} ${slot.deliveryPartner?.phone}`;

                const displaySlotName = getDisplaySlotName(index);
                const displaySlotTime = getDisplaySlotTime(index);
                const displaySlotDate = getDisplaySlotDate(index);
                const displaySlotDeliveryStatus = getDisplaySlotDeliveryStatus(index);

                if (formData.some((formDatum) => formDatum.name === displaySlotName)) {
                    return acc;
                }
                if (formData.some((formDatum) => formDatum.name === displaySlotTime)) {
                    return acc;
                }
                if (formData.some((formDatum) => formDatum.name === displaySlotDate)) {
                    return acc;
                }
                if (formData.some((formDatum) => formDatum.name === displaySlotDeliveryStatus)) {
                    return acc;
                }

                const datamodelArray = [
                    {
                        name: displaySlotTime,
                        label: `Slot Time`,
                        fieldType: {
                            name: 'text',
                        },
                        readonly: true,
                        isTableHeader: false,
                        isAccordian: true,
                        placeholder: 'Slot Time',
                    },
                    {
                        name: displaySlotDate,
                        label: `Slot Date`,
                        fieldType: {
                            name: 'text',
                        },
                        readonly: true,
                        isTableHeader: false,
                        isAccordian: true,
                        placeholder: 'Slot Date',
                    },
                    {
                        name: displaySlotDeliveryStatus,
                        label: `Slot Delivery Status`,
                        fieldType: {
                            name: 'text',
                        },
                        readonly: true,
                        isTableHeader: false,
                        isAccordian: true,
                        placeholder: 'Slot Delivery Status',
                    },
                    {
                        name: displaySlotName,
                        label: `Slot ${index + 1}`,
                        fieldType: {
                            name: 'text',
                        },
                        readonly: true,
                        isTableHeader: false,
                        isAccordian: true,
                        placeholder: 'Delivery partner not selected for this slot',
                    },
                    {
                        name: getInputSlotName(index),
                        label: `Slot ${index + 1}`,
                        fieldType: {
                            name: 'drawerForm',
                            drawerFormOptions: availableDeliveryPartners.map(
                                (deliveryPartner: {
                                    firstName: string;
                                    lastName: string;
                                    phone: string;
                                    id: string;
                                }) => ({
                                    value: `${deliveryPartner.firstName}  ${deliveryPartner.phone}`,
                                    name: deliveryPartner.id,
                                }),
                            ),
                            entityName: ORDER_LIST_ENTITY,
                            entityId,
                            apiEndPoint: 'deliveryPartner',
                            apiMethod: 'PATCH',
                            buttonText: 'Assign Delivery Partner',
                            payloadFormatterKey: 'delivery-partner',
                            additionalData: {
                                slotId: slot.id,
                                slotDate: slot.slotDate,
                                relatedDisplayInputName: displaySlotName,
                            },
                            formInitialValues: {
                                [getInputSlotName(index)]: !slot.deliveryPartner?.firstName
                                    ? slot.deliveryPartner?.id
                                    : deliveryPartnerName,
                            },
                            placeholder: `Select delivery partner for slot ${index + 1}`,
                        },
                        isTableHeader: false,
                        isAccordian: true,
                        deliveryPartnerCount: availableDeliveryPartners.length,
                    },
                ] as any;

                if (index === 0) {
                    datamodelArray[0].title = 'Assigned Delivery Partner';
                }

                return [...acc, ...datamodelArray];
            }, []),
        ]);
    }
};

interface DeliverySlot {
    deliveryPartner?: {
        firstName: string;
        lastName: string;
        phone: string;
    };
    startTime: number;
    endTime: number;
    slotDate: any;
    deliveryStatus?: any;
}

const getFormattedTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const minutesRemainder = minutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = String(minutesRemainder).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${period}`;
};

const getDeliveryPartners = (availableSlots: DeliverySlot[]): Record<string, string> =>
    availableSlots.reduce((acc, slot, index) => {
        const { deliveryPartner, startTime, endTime, slotDate, deliveryStatus } = slot;

        if (deliveryPartner) {
            const startTimeString = getFormattedTime(startTime);
            const endTimeString = getFormattedTime(endTime);
            const timeSlot = `${startTimeString} - ${endTimeString}`;
            const { firstName, lastName, phone } = deliveryPartner;
            const deliveryPartnerName = `${firstName} ${lastName} ${phone}`;

            return {
                ...acc,
                [getDisplaySlotName(index)]: deliveryPartnerName,
                [getDisplaySlotTime(index)]: timeSlot,
                [getDisplaySlotDate(index)]: slotDate,
                [getDisplaySlotDeliveryStatus(index)]: deliveryStatus,
            };
        }
        if ((startTime && endTime) || slotDate) {
            const startTimeString = getFormattedTime(startTime);
            const endTimeString = getFormattedTime(endTime);
            const timeSlot = `${startTimeString} - ${endTimeString}`;

            return {
                ...acc,
                [getDisplaySlotTime(index)]: timeSlot,
                [getDisplaySlotDate(index)]: slotDate,
                [getDisplaySlotDeliveryStatus(index)]: deliveryStatus,
            };
        }

        return acc;
    }, {});

type UpdateFormFields = (arg: { incomingFormData: any; form: FormInstance }) => void;

export const updateFormFields: UpdateFormFields = (arg) => {
    const { incomingFormData, form } = arg;

    const availableSlots = incomingFormData?.slots;

    if (availableSlots) {
        const deliveryPartners = getDeliveryPartners(availableSlots);
        form.setFieldsValue(deliveryPartners);
    }
};

type GetUpdatedFormData = (arg: {
    name: any;
    values: any;
    additionalData: any;
    payloadFormatterKey: PayloadFormatterKey | undefined;
    drawerFormOptions: any[];
}) => any;

export const getUpdatedFormData: GetUpdatedFormData = (arg) => {
    const { name, values, additionalData, payloadFormatterKey, drawerFormOptions } = arg;

    switch (payloadFormatterKey) {
        case 'delivery-partner': {
            const { relatedDisplayInputName } = additionalData;
            const { [name]: deliveryPartnerId } = values;

            const drawerFormOption = drawerFormOptions?.find((option: any) => option?.id === deliveryPartnerId);

            if (!drawerFormOption) {
                return {};
            }

            const deliveryPartnerName = drawerFormOption.values;

            return { [relatedDisplayInputName]: deliveryPartnerName };
        }

        default: {
            return { [name]: values[name] };
        }
    }
};
