/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Button, Input } from 'antd';
import './style.scss';

const { TextArea } = Input;

type GarageModalProps = {
    children?: any;
    postComment?: (d: any) => any;
    currentTab?: string;
    isOpen: boolean;
    onclose: () => void;
};

const GarageModal: React.FC<GarageModalProps> = ({ postComment, currentTab, isOpen, onclose }) => {
    const [addCommentInModal, setAddCommentInModal] = useState<any>({ 'section': currentTab, 'comment': '' });
    const modalRef = useRef<HTMLDivElement>(null);

    const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setAddCommentInModal((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddComment = () => {
        if (postComment) {
            if (addCommentInModal.comment.trim() !== '') {
                postComment(addCommentInModal);
                setAddCommentInModal({ 'section': 'computer card', 'comment': '' });
                onclose();
            }
        }
    };

    const handleOverlayClick = (e: React.MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(e.target as Node) && onclose) {
            onclose();
        }
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && onclose) {
                onclose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onclose]);

    return (
        <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            <div className='garageModal' ref={modalRef}>
                <div className='modalCloseBtn'>
                    <span style={{ cursor: 'pointer' }} onClick={onclose}>
                        X
                    </span>
                </div>
                <div className='commentCard'>
                    <TextArea
                        placeholder='Add comment'
                        maxLength={100}
                        name='comment'
                        value={addCommentInModal.comment}
                        onChange={handleComment}
                    />
                </div>
                <Button id='commentBtn' onClick={handleAddComment}>
                    Add Comment
                </Button>
            </div>
        </div>
    );
};

export default GarageModal;
