/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Button } from 'antd';
import { DatePicker, Space, TimePicker } from 'antd';
import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import callApi from '../../Api/config';
import { PICK_UP_ENTITY } from '../../constants/EntityName';
import { toastError, toastSuccess } from '../../service/Toast';

import './style.scss';

type ThirdPartyFormProps = {
    id: string;
    setIsModal: (values: any) => void;
    timeSlot?: string;
    pickupDate?: string;
    refreshPage: (flag: boolean) => void;
};

const POST_URL = PICK_UP_ENTITY;

let flag = 0;

const ThirdPartyForm: React.FC<ThirdPartyFormProps> = ({ id, setIsModal, pickupDate, timeSlot, refreshPage }) => {
    const [formData, setFormData] = useState<any>({});
    const [driverData, setDriverData] = useState<any>({});
    const [formatStartDate, setFormatStartDate] = useState('');
    const [formatEndDate, setFormatEndDate] = useState('');

    function extractTimes(timeSlot: any) {
        const times = timeSlot.match(/\b\d{1,2}:\d{2}\b/g);

        if (times && times.length === 2) {
            const startTime = times[0];
            const endTime = times[1];
            return { startTime, endTime };
        } else {
            console.error('Invalid time slot format');
            return null;
        }
    }

    const extractedTimes = extractTimes(timeSlot);

    const onSubmit = async () => {
        try {
            const obj: any = {};
            if (id) {
                obj.assignedFrom = 'booking';
            } else {
                obj.assignedFrom = 'warranty';
            }

            if (!formData.timeSlot) {
                formData.timeSlot = timeSlot;
            }
            if (!formData.date) {
                formData.date = pickupDate;
            }
            const res = await callApi(POST_URL + '/assign/' + id, 'POST', { ...obj, ...formData, ...driverData });
            if (res?.status) {
                toastSuccess('Pickup assigned successfully');
            } else {
                toastError(res?.data?.error);
            }
            setIsModal(false);
            refreshPage(true);
            setDriverData(() => ({ name: '', phone: '' }));
        } catch (error) {
            toastError('Something went wrong');
        }
    };

    function formatDate(inputDate: string) {
        const date = new Date(inputDate);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function formatTime(inputDate: string) {
        const date = new Date(inputDate);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${hours}:${minutes}`;
    }

    const handleTimeSlot = (e: any) => {
        const startTime = formatTime(e[0]._d);
        const endTime = formatTime(e[1]._d);
        const obj = {
            timeSlot: startTime + '-' + endTime,
        };
        setFormData((prev: any) => ({ ...prev, ...obj }));
        if (flag === 0) {
            setFormatStartDate(obj.timeSlot.split('-')[1]);
            flag = 1;
        } else {
            setFormatEndDate(obj.timeSlot.split('-')[1]);
        }
    };

    const handleDate = (e: any) => {
        const date = formatDate(e._d);
        const obj = {
            date: date,
        };
        setFormData((prev: any) => ({ ...prev, ...obj }));
    };

    const handleInput = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setDriverData((prev: any) => ({ ...prev, [name]: value }));
    };

    const dateFormat = 'YYYY-MM-DD';

    return (
        <div>
            <div>
                <h2 style={{ fontWeight: 'bold' }}>Assign Third Party</h2>
                <p>Fill the fields to complete the third party details.</p>
            </div>

            <div className='commonStyle'>
                <h3>Date</h3>
                <Space direction='vertical'>
                    <DatePicker
                        value={moment(formData?.date || pickupDate, dateFormat)}
                        onChange={handleDate}
                        format={dateFormat}
                        disabled
                    />
                </Space>
            </div>
            <div className='commonStyle'>
                <h3>Pick Up Slot</h3>
                <TimePicker.RangePicker
                    onChange={handleTimeSlot}
                    value={[
                        moment(formatStartDate || extractedTimes?.startTime, 'HH:mm'),
                        moment(formatEndDate || extractedTimes?.endTime, 'HH:mm'),
                    ]}
                    format='HH:mm'
                    disabled
                />
            </div>

            <div className='commonStyle'>
                <h3>Driver Name</h3>
                <input
                    className='modalInput'
                    placeholder='Type driver name'
                    type='text'
                    name='name'
                    value={driverData.name}
                    onChange={handleInput}
                />
            </div>
            <div className='commonStyle'>
                <h3>Driver Contact Number</h3>
                <input
                    className='modalInput'
                    placeholder='Type driver phone number'
                    type='text'
                    name='phone'
                    value={driverData.phone}
                    onChange={handleInput}
                    maxLength={8}
                />
            </div>

            <Button id='btnAssign' onClick={onSubmit}>
                Assign Pickup
            </Button>
        </div>
    );
};

export default ThirdPartyForm;
