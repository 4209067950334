import { errorMsg } from '../constants/messages';

const serviceListFormData = [
    {
        name: 'category',
        label: 'Category',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Category' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'subcategory',
        label: 'Subcategory',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Subcategory' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'levels',
        label: 'Levels Added',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Levels Added' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'status',
        label: 'Status',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Status', columnType: 'toggle' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { serviceListFormData };
