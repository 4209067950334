// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectAddContainer {
  margin-top: 20px; }
  .selectAddContainer .ant-col {
    max-width: 100% !important;
    flex: auto; }
  .selectAddContainer .accordianInput {
    width: 100%;
    margin-top: 10px; }

.ant-checkbox + span {
  font-size: 16px;
  padding: 5px; }

.newCheck {
  height: 10vh;
  display: flex;
  align-items: center; }
  .newCheck .newCheckbox {
    margin-top: 10px;
    outline: none;
    border: 1px solid #999797;
    height: 30px;
    width: 98%;
    padding: 8px; }
  .newCheck .arrowIcon {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: 10px; }

#btnAssign {
  width: calc(100% - 48px); }

.accordianInput .textEditor {
  max-height: 250px;
  overflow-y: scroll; }
`, "",{"version":3,"sources":["webpack://./src/components/ServiceAddLevel/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAgB,EAAA;EADpB;IAIQ,0BAA0B;IAC1B,UAAU,EAAA;EALlB;IASQ,WAAW;IACX,gBAAgB,EAAA;;AAIxB;EACI,eAAe;EACf,YAAY,EAAA;;AAGhB;EACI,YAAY;EACZ,aAAa;EACb,mBAAmB,EAAA;EAHvB;IAMQ,gBAAgB;IAChB,aAAa;IACb,yBAAoC;IACpC,YAAY;IACZ,UAAU;IACV,YAAY,EAAA;EAXpB;IAeQ,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,iBAAiB,EAAA;;AAIzB;EACI,wBAAwB,EAAA;;AAG5B;EAEQ,iBAAiB;EACjB,kBAAkB,EAAA","sourcesContent":[".selectAddContainer {\n    margin-top: 20px;\n\n    .ant-col {\n        max-width: 100% !important;\n        flex: auto;\n    }\n\n    .accordianInput {\n        width: 100%;\n        margin-top: 10px;\n    }\n}\n\n.ant-checkbox + span {\n    font-size: 16px;\n    padding: 5px;\n}\n\n.newCheck {\n    height: 10vh;\n    display: flex;\n    align-items: center;\n\n    .newCheckbox {\n        margin-top: 10px;\n        outline: none;\n        border: 1px solid rgb(153, 151, 151);\n        height: 30px;\n        width: 98%;\n        padding: 8px;\n    }\n\n    .arrowIcon {\n        display: flex;\n        align-items: center;\n        margin-top: 5px;\n        margin-left: 10px;\n    }\n}\n\n#btnAssign {\n    width: calc(100% - 48px);\n}\n\n.accordianInput {\n    .textEditor {\n        max-height: 250px;\n        overflow-y: scroll;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
