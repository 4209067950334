/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Image } from 'antd';

import { FacebookFilled, FileImageOutlined, InstagramFilled, TwitterCircleFilled } from '@ant-design/icons';

import callApi from '../../Api/config';
import { GARAGE_MANAGEMENT_ENTITY } from '../../constants/EntityName';
import { NextFrom } from '../../constants/AppConstant';

import NoImage from '../../assets/png/no-image.jpeg';

import './style.scss';

const GarageMarketing = () => {
    const [marketingData, setMarketingData] = useState<any>({});

    const location = useLocation();
    const garageId = location.search.slice(1);

    const mainGarageDetails = marketingData?.mainGarageDetails || {};
    const garageImages = mainGarageDetails.garageImage || [];
    const testimonialList = mainGarageDetails.testimonialList || [];
    const socialLinks = mainGarageDetails.socialLinks || [];

    useEffect(() => {
        const fetchData = async () => {
            const res = await callApi(
                `${GARAGE_MANAGEMENT_ENTITY}/application/${garageId}/${NextFrom.MARKETING}`,
                'GET',
            );
            setMarketingData(res?.data?.data);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const socialIcons: any = {
        'facebook': <FacebookFilled />,
        'instagram': <InstagramFilled />,
        'twitter': <TwitterCircleFilled />,
        'website': <FileImageOutlined />,
        'tiktok': <FileImageOutlined />,
    };

    return (
        <div>
            <div className='garageImgContainer'>
                <div className='garageImgTitle'>Garage Images</div>
                <div className='garageImages'>
                    {garageImages.map((image: any) => (
                        <Image
                            key={image.id}
                            src={image.imageUrl ? image.imageUrl : NoImage}
                            alt={`Garage Image ${image.id}`}
                            style={{ width: '260px', height: '200px', objectFit: 'cover' }}
                        />
                    ))}
                </div>
            </div>
            <div className='testimonialContainer'>
                <div className='testimonialTitle'>Customer Testimonials</div>
                <div className='testimonialCards'>
                    {testimonialList.map((testimonial: any) => (
                        <div key={testimonial.id} className='testimonialCommentCard'>
                            <p>{testimonial.comment}</p>
                            <div className='testimonialImage'>
                                <img
                                    src={testimonial.clientImageUrl ? testimonial.clientImageUrl : NoImage}
                                    alt='img'
                                />
                            </div>
                            <p className='testimonialName'>{testimonial.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='socialContainer'>
                <div className='socialTitle'>Social Links</div>
                <div className='socialCards'>
                    {socialLinks.map((socialLink: any) => (
                        <div className='socialLink' key={socialLink.id}>
                            <div className='socialIconContainer'>{socialIcons[socialLink.type.toLowerCase()]}</div>
                            <a href={socialLink.link} target='_blank' rel='noopener noreferrer'>
                                {socialLink.type}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GarageMarketing;
