import { Form, TimePicker } from 'antd';

import { InputInterface } from '../utils/forms/FormInterface';

import './style.scss';

interface TimeSlotInputProps {
    formInput: InputInterface;
    index: number;
}

const TimeSlotInput = (props: TimeSlotInputProps) => {
    const { formInput, index } = props;

    return (
        <Form.Item
            key={index}
            className='text'
            name={formInput.name}
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            labelAlign='left'
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
            ]}>
            <TimePicker.RangePicker format='HH:mm' />
        </Form.Item>
    );
};

export default TimeSlotInput;
