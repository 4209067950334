import { errorMsg } from '../../constants/messages';

const PackagesFormData = [
    {
        title: 'Packages Details',
        name: 'name',
        label: 'Package Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Package Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'service',
        label: 'Service',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Service',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Service',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'serviceName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'serviceAttributes',
        label: 'Service Attributes',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select an Service Attributes',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Service Attributes',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'serviceAttributesDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'plans',
        label: 'Plans',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select an Plans',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Plan',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'plansDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'documitraFees',
        label: 'Documitra Fees ($)',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: 'Documitra Fees should be in number',
        tableMeta: { title: 'Documitra Fee ($)' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'governmentFees',
        label: 'Government Fees ($)',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: 'Government Fees should be in number',
        tableMeta: { title: 'Government Fee ($)' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { PackagesFormData };
