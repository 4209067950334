// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-wrap {
  position: relative; }

.ant-modal {
  margin: 0px !important;
  padding: 0px !important;
  top: 0px !important;
  left: calc(100% - 520px); }

.ant-modal-content {
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll; }
`, "",{"version":3,"sources":["webpack://./src/components/ModalComponent/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB,EAAA;;AAGtB;EACI,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,wBAAwB,EAAA;;AAI5B;EACI,WAAW;EACX,iBAAiB;EACjB,kBAAkB,EAAA","sourcesContent":[".ant-modal-wrap{\n    position: relative;\n}\n\n.ant-modal{\n    margin: 0px !important;\n    padding: 0px !important;\n    top: 0px !important;\n    left: calc(100% - 520px)\n\n}\n\n.ant-modal-content{\n    width: 100%;\n    min-height: 100vh;\n    overflow-y: scroll;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
