// Define a function that takes two arguments: the function to debounce and the debounce timeout
const debounceFunction = (func: any, debounceTimeout: number) => {
    let debounceTime: any;

    // Return a new function that takes any number of arguments
    return (...args: any) => {
        // If there is already a timeout scheduled, clear it
        if (debounceTime) clearTimeout(debounceTime);

        // Set a new timeout that will call the passed-in function after the debounce timeout has elapsed
        debounceTime = setTimeout(() => {
            func(...args); // Call the passed-in function with the original arguments
        }, debounceTimeout);
    };
};

export default debounceFunction;
