import { useEffect, useState } from 'react';

import callApi from '../Api/config';

async function getResponse(entityName: string, queryParams: any = null) {
    const response = await callApi(`${entityName}`, 'GET', null, queryParams);
    return response;
}

/**
 * this function sets the additionalData state based on the additionalEntityName props. For example in the case of users tab, we need the additional data for roles, entity modules and actions which is set by this function. The data is stored as an object {/roles: {rolesObject}, /actions: {actionsObject}, ...otherFields}
 */
function useGetResponse(additionalEntityName: any) {
    const [additionalData, setAdditionalData] = useState<any>({});

    useEffect(() => {
        if (additionalEntityName instanceof Array) {
            const promises = additionalEntityName.map((entityName) => {
                const queryParams = 'queryParams' in entityName ? entityName.queryParams : null;

                return getResponse(entityName.name, queryParams);
            });

            const secondPromiseData: { name: string; totalCount: any }[] = [];

            Promise.all(promises)
                .then((result) => {
                    if (result.some((res) => !res?.status)) {
                        throw new Error();
                    }

                    const additionalData = result.reduce((acc, res, index) => {
                        typeof additionalEntityName === 'object' &&
                            additionalEntityName?.[index].getAllResponse &&
                            res?.data?.data &&
                            res.data.data.totalCount > res.data.data.limit &&
                            secondPromiseData.push({
                                name: `${(additionalEntityName?.[index] as { name: string }).name}`,
                                totalCount: res.data.data.totalCount,
                            });

                        return {
                            ...acc,
                            [`${(additionalEntityName?.[index] as { name: string }).name}`]: res?.data,
                        };
                    }, {});

                    if (secondPromiseData.length) {
                        const secondPromise = secondPromiseData.map((promiseData) =>
                            getResponse(promiseData.name, { limit: promiseData.totalCount }),
                        );

                        Promise.all(secondPromise)
                            .then((result) => {
                                if (result.some((res) => !res?.status)) {
                                    throw new Error();
                                }

                                const modifiedData = result.reduce(
                                    (acc: any, res, index) => ({
                                        ...acc,
                                        [secondPromiseData[index].name]: res?.data,
                                    }),
                                    {},
                                );

                                Object.assign(additionalData, modifiedData);
                                setAdditionalData(additionalData);
                            })
                            .catch((err) => {
                                // eslint-disable-next-line no-console
                                console.log('The error in fetching data is ', err);
                            });
                    } else {
                        setAdditionalData(additionalData);
                    }
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log('The error in fetching data is ', err);
                });
        }

        if (typeof additionalEntityName === 'string') {
            getResponse(additionalEntityName).then(
                (result) => result?.status && setAdditionalData({ [`${additionalEntityName}`]: result.data }),
            );
        }
    }, [additionalEntityName]);

    return additionalData;
}

export default useGetResponse;
