import React from 'react';

import { Modal } from 'antd';

import './style.scss';

type ModalComponentProps = {
    openModal: boolean;
    closeModal: () => any;
    children?: any;
};

// eslint-disable-next-line no-unused-vars
const ModalComponent: React.FC<ModalComponentProps> = ({ openModal, closeModal, children }) => (
    <>
        <div className='modalComponent'>
            <Modal open={openModal} onCancel={closeModal} footer={null}>
                {children}
            </Modal>
        </div>
    </>
);

export default ModalComponent;
