// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addServiceContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 40px; }
  .addServiceContainer .addServiceBranch {
    display: flex;
    justify-content: space-between;
    padding: 15px 0; }
    .addServiceContainer .addServiceBranch p {
      font-size: 18px;
      margin-bottom: 0; }
  .addServiceContainer .category .categoryHead {
    display: flex;
    justify-content: space-between;
    padding: 15px 0; }
  .addServiceContainer .category .subCategorySection {
    margin: 20px 0; }
  .addServiceContainer .submitSection {
    position: fixed;
    bottom: 5px;
    background-color: rgba(0, 0, 0, 0.6); }
    .addServiceContainer .submitSection .submitBtn {
      width: 300px;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000;
      color: #fff;
      border: none; }
`, "",{"version":3,"sources":["webpack://./src/components/AddService/style.scss"],"names":[],"mappings":"AAAA;EACI,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB,EAAA;EALvB;IAQQ,aAAa;IACb,8BAA8B;IAC9B,eAAe,EAAA;IAVvB;MAaY,eAAe;MACf,gBAAgB,EAAA;EAd5B;IAoBY,aAAa;IACb,8BAA8B;IAC9B,eAAe,EAAA;EAtB3B;IA0BY,cAAc,EAAA;EA1B1B;IA+BQ,eAAe;IACf,WAAW;IACX,oCAAoC,EAAA;IAjC5C;MAoCY,YAAY;MACZ,aAAa;MACb,aAAa;MACb,uBAAuB;MACvB,mBAAmB;MACnB,sBAAsB;MACtB,WAAW;MACX,YAAY,EAAA","sourcesContent":[".addServiceContainer {\n    width: 80%;\n    display: flex;\n    flex-direction: column;\n    margin: auto;\n    margin-bottom: 40px;\n\n    .addServiceBranch {\n        display: flex;\n        justify-content: space-between;\n        padding: 15px 0;\n\n        p {\n            font-size: 18px;\n            margin-bottom: 0;\n        }\n    }\n\n    .category {\n        .categoryHead {\n            display: flex;\n            justify-content: space-between;\n            padding: 15px 0;\n        }\n\n        .subCategorySection {\n            margin: 20px 0;\n        }\n    }\n\n    .submitSection {\n        position: fixed;\n        bottom: 5px;\n        background-color: rgba(0, 0, 0, 0.6);\n\n        .submitBtn {\n            width: 300px;\n            padding: 20px;\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            background-color: #000;\n            color: #fff;\n            border: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
