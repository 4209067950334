import { errorMsg } from '../../constants/messages';

type StatusType = { id: string; value: string; name: string }[];

export const statusOption: StatusType = [
    { id: '1', value: 'Draft', name: 'Draft' },
    { id: '2', value: 'Active', name: 'Active' },
    { id: '3', value: 'Archived', name: 'Archived' },
];

export const CountriesList: any = [
    { id: '1', value: 'India', name: 'India' },
    { id: '2', value: 'USA', name: 'USA' },
    { id: '3', value: 'China', name: 'China' },
    { id: '4', value: 'Nepal', name: 'Nepal' },
    { id: '4', value: 'Dhaka', name: 'Dhaka' },
];

const ServicesFormData = [
    {
        title: 'Service Details',
        name: 'name',
        label: 'Service Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Service Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'isActive',
        label: 'Is Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        placeholder: '',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Is Active',
            enableSort: false,
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'parameters',
        label: 'Parameters',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select an Parameters',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Parameters',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'parametersDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'countries',
        label: 'Countries',
        fieldType: {
            name: 'multiSelect',
            options: CountriesList,
        },
        placeholder: 'Click here to select an Countries',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Countries',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'countriesName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
];

export { ServicesFormData };
