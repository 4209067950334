/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import ServiceCategory from '../../components/ServiceCategory/ServiceCategory';
import ServiceSubCategory from '../../components/ServiceSubCategory/ServiceSubCategory';

import callApi from '../../Api/config';
import { updateServiceIdAction } from '../../pages/actions';
import ServiceCategoryCard from '../ServiceCategoryCard';
import { toastError, toastSuccess } from '../../service/Toast';
import { GARAGE_BRANCH_NAME_ENTITY, SERVICE_CREATE_ENTITY } from '../../constants/EntityName';

import './style.scss';

const AddService = () => {
    const [showCategory, setShowCategory] = useState<boolean>(false);
    const [subCategoryCount, setSubCategoryCount] = useState(0);

    const [categoryId, setCategoryId] = useState('');
    const { globalReducer }: any = useSelector((state) => state);
    const prevStateData = globalReducer.serviceIdData;
    const [branchOption, setBranchOption] = useState([]);
    const [isBranchOption, setIsBranchOption] = useState(false);

    const allIdData = globalReducer.serviceIdData;

    const dispatch = useDispatch();

    const handleCategoryClick = () => {
        setShowCategory(true);
    };

    // add multiple sub-categories and save the categoryId in redux-store
    const handleSubCategory = (id: any, title: any) => {
        setSubCategoryCount((prev) => prev + 1);
        if (typeof id !== 'string') {
            id = categoryId;
        }
        dispatch(
            updateServiceIdAction({
                ...prevStateData,
                categoryId: id,
                ['index_' + subCategoryCount]: subCategoryCount,
            }),
        );
        setCategoryId(id);
    };

    // save the garageId to store and enable add category button
    const handleOptionSelect = (id: any) => {
        setIsBranchOption(true);
        dispatch(
            updateServiceIdAction({
                ...prevStateData,
                garageId: id,
            }),
        );
    };

    // Fetch branch names and set them as branch drop-down select options
    useEffect(() => {
        const fetchOptionData = async () => {
            try {
                const res = await callApi(GARAGE_BRANCH_NAME_ENTITY, 'GET');
                const data = res?.data?.data?.map((item: any) => ({ id: item.id, title: item.branchName }));
                setBranchOption(data);
            } catch (error) {
                console.error('Error fetching branch data:', error);
            }
        };
        fetchOptionData();
    }, []);

    // format level data to set to the final post api
    const levelDto = (index: number) =>
        globalReducer?.levelData
            .filter((item: any) => item.index === index)
            .map((data: any) => ({
                level: {
                    id: data.labelId,
                },
                price: parseInt(data.priceValue),
                discountedPrice: parseInt(data.discountPriceValue),
                description: data.descriptionValue,
            }));

    const getSubCategoryId = (CurrentIndex: number) => allIdData['subCategoryId_' + CurrentIndex];

    // format sub-category data to set to final post api
    const subCategoryFormat = (index: number) => {
        if (allIdData['deleteIndex'] === index) {
            return null;
        }
        const newCard: any = {};
        newCard['subCategory'] = {
            id: getSubCategoryId(index),
        };
        if (globalReducer.imageUrl[index]) {
            newCard['imageUrl'] = globalReducer.imageUrl[index];
        }

        if (allIdData['modelId_' + index]) {
            newCard['modelDto'] = allIdData['modelId_' + index].map((item: any) => ({
                model: { id: item },
            }));
        }

        newCard['levelDto'] = levelDto(index);
        if (!newCard.subCategory.id || !newCard.levelDto.length || !newCard.imageUrl || !newCard.modelDto.length) {
            return undefined;
        }

        return newCard;
    };

    // format POST data and hit POST API on submit
    const handleSubmitPostData = async () => {
        try {
            const serviceListPostData = {
                createServiceDto: [
                    {
                        category: {
                            id: allIdData.categoryId,
                        },
                        garage: {
                            id: allIdData.garageId,
                        },
                        subCategoryDto: [...Array(subCategoryCount)]
                            .map((_, index) => subCategoryFormat(index))
                            .filter((item) => item !== null),
                    },
                ],
            };

            const checkPayload = serviceListPostData.createServiceDto[0].subCategoryDto;

            if (!checkPayload.length || checkPayload.includes(undefined)) {
                toastError('All fields required');
                return;
            }
            const res: any = await callApi(SERVICE_CREATE_ENTITY, 'POST', serviceListPostData);
            if (res?.status) {
                toastSuccess('Service Created Successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                toastError(res?.data?.error);
            }
        } catch (error) {
            console.error('Error submitting service data:', error);
        }
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div className='addServiceContainer'>
            <div className='addServiceHeading'>
                <h1>Add Services</h1>
            </div>
            <div className='addServiceBranch'>
                <Select
                    style={{ width: 200 }}
                    placeholder='Select Branch'
                    optionFilterProp='children'
                    options={branchOption.map((item: any) => ({ value: item.id, label: item.title }))}
                    onSelect={handleOptionSelect}
                    filterOption={filterOption}
                />
            </div>
            <hr />
            <div className='category'>
                <div className='categoryHead'>
                    <h2>Select Categories</h2>
                    <Button onClick={handleCategoryClick} disabled={!isBranchOption ? true : false}>
                        + Add Category
                    </Button>
                </div>
                <ServiceCategoryCard />

                {showCategory && <ServiceCategory handleSubCategory={handleSubCategory} />}
                <div className='subCategorySection'>
                    {[...Array(subCategoryCount)].map((_, index) => (
                        <ServiceSubCategory key={index} categoryId={categoryId} index={index} />
                    ))}
                </div>
            </div>
            <div className='submitSection'>
                <Button className='submitBtn' onClick={handleSubmitPostData}>
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default AddService;
