// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commonStyle {
  padding: 10px 0px; }

.modalInput {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 12px;
  width: 400px;
  height: 35px; }
  .modalInput:focus-visible, .modalInput:hover {
    border: 1px solid red !important;
    outline: none; }

#btnAssign {
  position: absolute;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.842);
  color: rgba(255, 255, 255, 0.959);
  height: 40px;
  width: 400px; }
`, "",{"version":3,"sources":["webpack://./src/components/ThirdPartyForm/style.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB,EAAA;;AAGrB;EACI,oCAAoC;EACpC,aAAa;EACb,YAAY;EACZ,YAAY,EAAA;EAJhB;IAOQ,gCAAgC;IAChC,aAAa,EAAA;;AAIrB;EACI,kBAAkB;EAClB,YAAY;EACZ,sCAAsC;EACtC,iCAAiC;EACjC,YAAY;EACZ,YAAY,EAAA","sourcesContent":[".commonStyle{\n    padding: 10px 0px;\n}\n\n.modalInput{\n    border: 1px solid rgba(0, 0, 0, 0.3);\n    padding: 12px;\n    width: 400px;\n    height: 35px;\n\n    &:focus-visible, &:hover{\n        border: 1px solid red !important;\n        outline: none;\n    }\n}\n\n#btnAssign{\n    position: absolute;\n    bottom: 10px;\n    background-color: rgba(0, 0, 0, 0.842);\n    color: rgba(255, 255, 255, 0.959);\n    height: 40px;\n    width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
