import React from 'react';
import { Typography } from 'antd';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface LevelEarningChartProps {
    levelName: string[];
    totalAmount: number[];
}

const LevelEarningChart = (props: LevelEarningChartProps) => {
    const { levelName, totalAmount } = props;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Levels Earning',
            },
        },
        scales: {
            x: {
                min: 100,
                max: 5000,
            },
            y: {
                beginAtZero: true,
            },
        },
        indexAxis: 'y' as any,
    };

    const labels = levelName;

    const randomColors = totalAmount?.map(
        () => `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.8)`,
    );

    const data = {
        labels,
        datasets: [
            {
                label: 'Levels Earning',
                data: totalAmount,
                backgroundColor: randomColors,
                borderRadius: 15,
                barThickness: 15,
            },
        ],
    };

    return (
        <div style={{ padding: '15px' }}>
            <Typography.Title level={3} style={{ color: 'black', marginTop: '0px' }}>
                Levels Earning
            </Typography.Title>
            <Typography style={{ marginBottom: '15px', color: 'grey' }}>
                Total Earnings by garages based on each service level
            </Typography>
            <Bar options={options} data={data} />
        </div>
    );
};

export default LevelEarningChart;
