// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-box {
  display: grid;
  grid-template-columns: 180px 1fr;
  overflow: hidden; }
  .landing-box .landing-box-content {
    height: 100vh;
    overflow-y: auto;
    padding: 20px 15px; }
`, "",{"version":3,"sources":["webpack://./src/pages/Landing/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,gCAAgC;EAChC,gBAAgB,EAAA;EAHpB;IAMQ,aAAa;IACb,gBAAgB;IAChB,kBAAkB,EAAA","sourcesContent":[".landing-box {\n    display: grid;\n    grid-template-columns: 180px 1fr;\n    overflow: hidden;\n\n    .landing-box-content {\n        height: 100vh;\n        overflow-y: auto;\n        padding: 20px 15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
