import Accordian from '../../components/Accordian/Accordian';
import { InputInterface } from './FormInterface';

function getAllAccordions(formTemplate: InputInterface[], getFormElement: any) {
    const filteredFormTemplate = formTemplate.filter((x) => !x.hideFromAbstractForm);
    let arr = [];
    const allAccordions = [];

    for (let i = 0; i <= filteredFormTemplate.length; i++) {
        if (filteredFormTemplate[i]?.title) {
            const title = filteredFormTemplate[i].title;
            if (filteredFormTemplate[i]?.isAccordian) {
                arr.push(getFormElement(filteredFormTemplate[i], i));
            }

            for (let j = i + 1; j <= filteredFormTemplate.length && !filteredFormTemplate[j]?.title; j++) {
                if (filteredFormTemplate[j]?.isAccordian) {
                    arr.push(getFormElement(filteredFormTemplate[j], j));
                    i = j;
                }
            }
            const singleAccordion = (
                <Accordian title={title} key={i}>
                    {arr}
                </Accordian>
            );
            arr = [];
            allAccordions.push(singleAccordion);
        } else {
            if (filteredFormTemplate[i]?.isAccordian) {
                allAccordions.push(getFormElement(filteredFormTemplate[i], i));
            }
        }
    }

    return allAccordions;
}

export { getAllAccordions };
