/* eslint-disable no-unused-vars */
import { useState } from 'react';

import AbstractLanding from '../Entity/AbstractLanding';
import { orderListingData as initialOrderListData } from '../../datamodels/OrderListing';
import { getTableHeaders } from '../../utils/forms/FormDataModel';
import { ALL_ORDER_LIST_ENTITY } from '../../constants/EntityName';

import './style.scss';

const OrderList = {
    REQUESTED: 'requested',
    ACTIVE: 'active',
    COMPLETED: 'completed',
    RETURN: 'return',
};

const ActiveOrderCurrentTab = {
    REQUESTED: 'Requested Orders',
    ACTIVE: 'Active Orders',
    COMPLETED: 'Completed Orders',
    RETURN: 'Return Orders',
};

const OrderListing = () => {
    const [currentTab, setCurrentTab] = useState<string>(ActiveOrderCurrentTab.REQUESTED);

    const [orderListData, setOrderListData] = useState(initialOrderListData);

    const tableHeaders: any = getTableHeaders(orderListData);

    // TODO may be used in future
    // const history = useHistory();
    // const handleNavigateToDashboard = () => {
    //     history.push(DASHBOARD);
    // };

    const OrderListTabsConfig: any = {
        [ActiveOrderCurrentTab.REQUESTED]: (
            <AbstractLanding
                headerName={''}
                addName={''}
                entityName={`${ALL_ORDER_LIST_ENTITY}/${OrderList.REQUESTED}`}
                tableHeaders={tableHeaders}
                disableSearch
                disableAdd
            />
        ),
        [ActiveOrderCurrentTab.ACTIVE]: (
            <AbstractLanding
                headerName={''}
                addName={''}
                entityName={`${ALL_ORDER_LIST_ENTITY}/${OrderList.ACTIVE}`}
                tableHeaders={tableHeaders}
                disableSearch
                disableAdd
            />
        ),
        [ActiveOrderCurrentTab.COMPLETED]: (
            <AbstractLanding
                headerName={''}
                addName={''}
                entityName={`${ALL_ORDER_LIST_ENTITY}/${OrderList.COMPLETED}`}
                tableHeaders={tableHeaders}
                disableSearch
                disableAdd
            />
        ),
        [ActiveOrderCurrentTab.RETURN]: (
            <AbstractLanding
                headerName={''}
                addName={''}
                entityName={`${ALL_ORDER_LIST_ENTITY}/${OrderList.RETURN}`}
                tableHeaders={tableHeaders}
                disableSearch
                disableAdd
            />
        ),
    };
    return (
        <div>
            <div className='pickUpTitle'>
                <h2>{currentTab} List</h2>
            </div>
            {/* TODO may be used in future */}
            {/* <div className='backToDashboard' onClick={handleNavigateToDashboard}></div> */}
            <div className='tableTopHeader'></div>
            <div className='tabLinks'>
                <div className='links'>
                    {Object.entries(OrderListTabsConfig).map(([tabName]) => (
                        <p
                            key={tabName}
                            id={tabName.replace(/\s+/g, '')}
                            className={currentTab === tabName ? 'active' : ''}
                            onClick={() => setCurrentTab(tabName)}>
                            {tabName}
                        </p>
                    ))}
                </div>
            </div>
            <div className='orderListBar'>{OrderListTabsConfig[currentTab]}</div>
        </div>
    );
};

export default OrderListing;
