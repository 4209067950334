/* eslint-disable no-unused-vars */
import {
    SUBCATEGORIES_ENTITY,
    USERS_ENTITY,
    DEFECT_TYPES_ENTITY,
    COMPLAINTS_ENTITY,
    MANAGER_TECHNICIAN_ENTITY,
    ASSETS_ENTITY,
    LOCATIONS_ENTITY,
    CATEGORIES_ENTITY,
    CONTACT_INFO,
    APPLICATION_FORM_PACKAGE_PLANS,
    APPLICATION_FORM_QUESTIONS,
    APPLICATION_FORM_SERVICES,
    APPLICATION_FORM_PACKAGES,
    SERVICE_PROCESS_STEPS,
    SERVICE_PROCESS_DETAILS,
    SERVICE_PROCESS_SUBTITLE,
    MODELS_ENTITY,
    CATEGORY_SUBCATEGORIES_ENTITY,
    COUPONS_ENTITY,
    CATEGORY_CATEGORIES_ENTITY,
    MAKES_ENTITY,
    THIRD_PARTY_CHARGES_ENTITY,
    TOWING_CHARGES_ENTITY,
    GARAGE_COMMISSION_TYPE,
    COMMISSION_LIST_ENTITY,
} from '../constants/EntityName';

// It is hard-coded data and needs to be updated whenever the id of Categories change on backend
export const categoryIds: { [key: string]: string } = {
    fresh: '1',
    kitchen: '2',
};

export const getOutgoingPayload = (
    entityName: string,
    rawPayload: any,
    additionalData: any,
    extraPayloadData?: any,
    incomingFormData?: any,
    nestedEntityName?: string,
    isCreateMode?: boolean,
) => {
    switch (entityName) {
        case USERS_ENTITY: {
            return Object.entries(rawPayload).reduce(
                (acc: any, [key, value]: any) =>
                    // if (key === 'role') {
                    //     return { ...acc, [key]: { id: value } };
                    // }
                    ({ ...acc, [key]: value }),
                {},
            );
        }

        case COMMISSION_LIST_ENTITY: {
            const { charge, commissionType } = rawPayload;

            if (!isCreateMode) {
                return {
                    charge: Number(charge),
                    commissionType: commissionType,
                };
            } else {
                return {
                    ...rawPayload,
                };
            }
        }

        case THIRD_PARTY_CHARGES_ENTITY:
        case TOWING_CHARGES_ENTITY: {
            const { charge, chargeType } = rawPayload;

            const payload = {
                charge: Number(charge),
                chargeType: chargeType,
            };

            return {
                ...payload,
            };
        }

        case MODELS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'make') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case CATEGORY_SUBCATEGORIES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'category') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_SERVICES: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'parameters') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_PACKAGE_PLANS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'features') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'documents') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case CONTACT_INFO: {
            return Object.entries(rawPayload).reduce(
                (acc: any, [key, value]: any) => {
                    if (key === 'facebook') {
                        // Assuming value is a string representing the social media link
                        const socialMediaLinks = {
                            ...acc.socialMediaLinks,
                            [key]: `${rawPayload.facebook}`,
                        };
                        return { ...acc, socialMediaLinks };
                    }
                    if (key === 'twitter') {
                        // Assuming value is a string representing the social media link
                        const socialMediaLinks = {
                            ...acc.socialMediaLinks,
                            [key]: `${rawPayload.twitter}`,
                        };
                        return { ...acc, socialMediaLinks };
                    }
                    return { ...acc, [key]: value };
                },
                {
                    contactNumbers: ['string'],
                    email: 'string',
                    socialMediaLinks: {},
                },
            );
        }

        case GARAGE_COMMISSION_TYPE: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'garageName') {
                    return { ...acc, ['garage']: { id: value } };
                }
                if (key === 'commission') {
                    return { ...acc, ['commission']: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_QUESTIONS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'service') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case SERVICE_PROCESS_DETAILS: {
            const { description, sequence, heading, service } = rawPayload;
            const payload = {
                heading: heading,
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    service: { id: service },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case SERVICE_PROCESS_SUBTITLE: {
            const { description, sequence, heading, title } = rawPayload;
            const payload = {
                heading: heading,
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    title: { id: title },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case SERVICE_PROCESS_STEPS: {
            const { description, sequence, service } = rawPayload;
            const payload = {
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    service: { id: service },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case COUPONS_ENTITY: {
            const { name, description, couponCode, discount, validFrom, validTo, discountType, status, usesPerUser } =
                rawPayload;
            const formatDate = (date: any) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            };

            // Convert validFrom and validTo to local dates and format
            const formattedValidFrom = formatDate(new Date(validFrom));
            const formattedValidTo = formatDate(new Date(validTo));

            const payload = {
                name: name,
                description: description,
                couponCode: couponCode,
                discount: discount,
                validFrom: formattedValidFrom,
                validTo: formattedValidTo,
                discountType: discountType,
                status: status,
                usesPerUser: Number(usesPerUser),
            };
            return {
                ...payload,
            };
        }

        case APPLICATION_FORM_PACKAGES: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'serviceAttributes') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'plans') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'service') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case ASSETS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case LOCATIONS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case SUBCATEGORIES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }
                if (key === 'category') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case CATEGORIES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'asset') {
                    return { ...acc, [key]: { id: value } };
                }
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case COMPLAINTS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'defectType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case MANAGER_TECHNICIAN_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'technicians') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }
            }, {});
        }
    }

    const entityNamesArray = entityName?.split('/');
    const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];

    switch (`/${requiredEntityName}`) {
        default:
            return rawPayload;
    }
};
