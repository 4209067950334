/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import callApi from '../../Api/config';
import { GARAGE_MANAGEMENT_ENTITY } from '../../constants/EntityName';
import { NextFrom } from '../../constants/AppConstant';
import NoImage from '../../assets/png/no-image.jpeg';

import './style.scss';

const GarageServices = () => {
    const [serviceData, setServiceData] = useState<any>({});

    const location = useLocation();
    const garageId = location.search.slice(1);

    const mainGarageDetails = serviceData?.mainGarageDetails || [];

    useEffect(() => {
        const fetchData = async () => {
            const res = await callApi(`${GARAGE_MANAGEMENT_ENTITY}/application/${garageId}/${NextFrom.SERVICE}`, 'GET');
            setServiceData(res?.data?.data);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {mainGarageDetails.map((category: any) => (
                <div key={category.category.serviceId} className='regularServiceContainer'>
                    <div className='regularServiceTitle'>{category.category.serviceName}</div>
                    <div className='regularServiceImgCards'>
                        {category.category.subCategory.map((subCategory: any) => (
                            <div className='imageCard' key={subCategory.serviceDetailId}>
                                <div className='serviceImage'>
                                    <img
                                        src={subCategory.subcategotyImage ? subCategory.subcategotyImage : NoImage}
                                        alt='image'
                                    />
                                </div>
                                <p className='subCategoryTitle'>{subCategory.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default GarageServices;
