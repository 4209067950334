import { errorMsg } from '../constants/messages';

const activePickUpFormData = [
    {
        name: 'bookingId',
        label: 'Booking Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Booking Id' },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'customerInfo',
        label: 'Customer Info',
        fieldType: {
            name: 'text',
        },
        required: false,
        tableMeta: {
            title: 'Customer Info',
        },
        errorMsg: errorMsg,
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'vehicleDetails',
        label: 'Vehicle Details',
        fieldType: {
            name: 'text',
        },
        required: false,
        tableMeta: {
            title: 'Vehicle Details',
        },
        errorMsg: errorMsg,
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'pickupAddress',
        label: 'Pick Up Address',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Pick Up Address',
            allowAddRecordClick: false,
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: true,
    },
    {
        name: 'dropAddress',
        label: 'Drop Address',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Drop Address',
            allowAddRecordClick: false,
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: true,
    },
    {
        name: 'bookingDate',
        label: 'Booking Date',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Booking Date' },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'pickupDetails',
        label: 'Pickup Date & Time',
        fieldType: {
            name: 'text',
        },
        required: false,
        tableMeta: {
            title: 'Pickup Date & Time',
        },
        errorMsg: errorMsg,
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
    },
    {
        name: 'make',
        label: 'Status',
        fieldType: {
            name: 'select',
            option: [
                { name: 'pickup-scheduled', value: 'pickup-scheduled' },
                { name: 'vehicle-in-transit', value: 'vehicle-in-transit' },
                { name: 'vehicle-reached', value: 'vehicle-reached' },
            ],
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Status',
            columnType: 'dropDown',
            name: 'select',
            allowAddRecordClick: true,
            option: [
                { name: 'Pickup Scheduled', value: 'pickup-scheduled' },
                { name: 'Vehicle in Transit', value: 'vehicle-in-transit' },
                { name: 'Vehicle Reached', value: 'vehicle-reached' },
            ],
        },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
        default: '',
    },
];

export { activePickUpFormData };
