import { message } from 'antd';

const toastSuccess = (msg = 'This is a success message') => {
    message.success(msg);
};

const toastError = (msg = 'This is an error message') => {
    message.error(msg);
};

const toastWarning = (msg = 'This is a warning message') => {
    message.warning(msg);
};

export { toastSuccess, toastError, toastWarning };
