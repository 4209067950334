/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import callApi from '../../Api/config';
import './style.scss';
import { GARAGE_MANAGEMENT_ENTITY } from '../../constants/EntityName';
import { NextFrom } from '../../constants/AppConstant';

const BankDetails = () => {
    const [bankData, setBankData] = useState<any>({});

    const location = useLocation();
    const garageId = location.search.slice(1);

    useEffect(() => {
        const fetchData = async () => {
            const res = await callApi(
                `${GARAGE_MANAGEMENT_ENTITY}/application/${garageId}/${NextFrom.BANK_DETAIL}`,
                'GET',
            );
            setBankData(res?.data?.data?.mainGarageDetails[0]);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const detailsArray = [
        { label: 'Bank Name', key: 'bankName' },
        { label: 'Account Holder Name', key: 'accountHolderName' },
        { label: 'Account Number', key: 'accountNumber' },
        { label: 'Bank Address', key: 'address' },
        { label: 'IBAN Code', key: 'IBAN' },
        { label: 'Swift Code', key: 'swift' },
    ];

    return (
        <div className='bankContainer'>
            <div className='bankDetailHeading'>Bank Details</div>
            {detailsArray.map((detail, index) => (
                <div key={index} className='bankDetailData'>
                    <span className='bankLeftSpan'>{detail.label}</span>
                    <span className='bankRightSpan'>{bankData?.[detail.key] ? bankData?.[detail.key] : 'NA'}</span>
                </div>
            ))}
        </div>
    );
};

export default BankDetails;
