import { errorMsg } from '../constants/messages';

const GarageCommissionFormData = [
    {
        title: 'Garage Commission',
        name: 'garageName',
        label: 'Garage Name',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Garage Name',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Garage Name',
            enableSort: false,
            allowAddRecordClick: true,
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'branch',
        label: 'Branch Name',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Garage Name',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Branch Name',
            enableSort: false,
            allowAddRecordClick: true,
        },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'commission',
        label: 'Commission Type',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Commission Type',
            allowAddRecordClick: true,
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { GarageCommissionFormData };
