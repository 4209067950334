// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headTitle h2 {
  font-size: 26px;
  font-weight: 600;
  margin-left: 30px; }

.privacyTermTab {
  width: 90%;
  margin: auto; }
  .privacyTermTab .ant-form-item {
    width: 100%; }
  .privacyTermTab .ant-col-offset-4 {
    margin-left: 0; }
  .privacyTermTab .ant-col-4 {
    display: none; }
  .privacyTermTab .ant-col-8 {
    width: 90%;
    flex: none;
    max-width: none; }
  .privacyTermTab .ant-form-item-control-input {
    width: 100%; }

.guidelineUpdate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 50px 0; }
  .guidelineUpdate button {
    background-color: #5454e7;
    color: #fff;
    height: 40px;
    width: 150px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer; }
  .guidelineUpdate .ant-btn-primary:hover, .guidelineUpdate .ant-btn-primary:focus {
    background: #5454e7;
    border-color: #5454e7;
    color: #fff; }

.ck .ck-editor__editable {
  height: 300px; }
`, "",{"version":3,"sources":["webpack://./src/pages/Guidelines/style.scss"],"names":[],"mappings":"AACA;EACI,eAAe;EACf,gBAAgB;EAChB,iBAAiB,EAAA;;AAGrB;EACI,UAAU;EACV,YAAY,EAAA;EAFhB;IAKQ,WAAW,EAAA;EALnB;IASQ,cAAc,EAAA;EATtB;IAaQ,aAAa,EAAA;EAbrB;IAiBQ,UAAU;IACV,UAAU;IACV,eAAe,EAAA;EAnBvB;IAuBQ,WAAW,EAAA;;AAInB;EACI,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,oBAAoB,EAAA;EAJxB;IAOQ,yBAAkC;IAClC,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe,EAAA;EAdvB;IAkBQ,mBAA4B;IAC5B,qBAA8B;IAC9B,WAAW,EAAA;;AAInB;EACI,aAAa,EAAA","sourcesContent":["\n.headTitle h2{\n    font-size: 26px;\n    font-weight: 600;\n    margin-left: 30px;\n}\n\n.privacyTermTab{\n    width: 90%;\n    margin: auto;\n\n    .ant-form-item{\n        width: 100%;\n    }\n\n    .ant-col-offset-4{\n        margin-left: 0;\n    }\n\n    .ant-col-4{\n        display: none;\n    }\n\n    .ant-col-8{\n        width: 90%;\n        flex: none;\n        max-width: none;\n    }\n\n    .ant-form-item-control-input{\n        width: 100%;\n    }\n}\n\n.guidelineUpdate{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    padding: 20px 50px 0;\n\n    button{\n        background-color: rgb(84, 84, 231);\n        color: #fff;\n        height: 40px;\n        width: 150px;\n        border: none;\n        border-radius: 5px;\n        font-size: 16px;\n        cursor: pointer;\n    }\n\n    .ant-btn-primary:hover, .ant-btn-primary:focus{\n        background: rgb(84, 84, 231);\n        border-color: rgb(84, 84, 231);\n        color: #fff;\n    }\n}\n\n.ck .ck-editor__editable{\n    height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
