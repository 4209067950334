/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    CustomerServiceTwoTone,
    ExclamationCircleOutlined,
    HomeTwoTone,
    PhoneTwoTone,
    StopTwoTone,
} from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Space, Typography } from 'antd';

import AbstractLanding from '../Entity/AbstractLanding';

import { approvedGarageData as initialApprovedData } from '../../datamodels/GarageManagement/ApprovedGarage';
import { onboardingRequestData as initialOnboardingData } from '../../datamodels/GarageManagement/OnboardingRequest';
import { blacklistedGaragesData as initialBlacklistedData } from '../../datamodels/GarageManagement/BlacklistedGarages';

import { getTableHeaders } from '../../utils/forms/FormDataModel';
import { GARAGE_MANAGEMENT_ENTITY } from '../../constants/EntityName';
import { GarageStatus } from '../../constants/AppConstant';
import callApi from '../../Api/config';
import ConfirmationModal from '../../components/ConfirmationModal';
import { toastSuccess } from '../../service/Toast';

import './style.scss';

const GarageActiveTab = {
    ONBOARDING_REQUEST: 'Onboarding Requested',
    APPROVED_REQUEST: 'Approved Garages',
    BLACK_LIST_REQUEST: 'Blacklisted Garages',
};

const GarageManagement = () => {
    const [currentTab, setCurrentTab] = useState<string>(GarageActiveTab.ONBOARDING_REQUEST);

    const [onboardingData, setOnboardingData] = useState(initialOnboardingData);
    const [approvedData, setApprovedData] = useState(initialApprovedData);
    const [blacklistedData, setBlacklistedData] = useState(initialBlacklistedData);
    const [garageName, setGarageName] = useState('');

    const [garageBlackId, setGarageBlackId] = useState('');

    const [garageCardStat, setGarageCardStat] = useState<any>({});

    const tableHeadersOnboarding: any = getTableHeaders(onboardingData);
    const tableHeadersApproved: any = getTableHeaders(approvedData);
    const tableHeadersBlacklisted: any = getTableHeaders(blacklistedData);

    const [isModal, setIsModal] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(false);

    const history = useHistory();

    const handleAdd = (argument1: any) => {
        const garageIdView = argument1.garageId;
        history.push(`${GARAGE_MANAGEMENT_ENTITY}/application` + '?' + garageIdView);
    };

    const handleBlacklist = async (argument1: any) => {
        setGarageName(argument1.garageName);
        setIsModal(true);
        setGarageBlackId(argument1.garageId);
        setRefresh(false);
    };

    const closeModal = () => {
        setIsModal(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await callApi(`/admin/garages-stat`, 'GET');
            setGarageCardStat(res?.data?.data);
        };

        fetchData();
    }, []);

    const tabsConfig: any = {
        [GarageActiveTab.ONBOARDING_REQUEST]: (
            <AbstractLanding
                headerName={''}
                addName={''}
                entityName={`${GARAGE_MANAGEMENT_ENTITY}/${GarageStatus.NEW}`}
                tableHeaders={tableHeadersOnboarding}
                disableSearch
                disableAdd
                onAdd={handleAdd}
            />
        ),
        [GarageActiveTab.APPROVED_REQUEST]: (
            <AbstractLanding
                headerName={''}
                addName={''}
                entityName={`${GARAGE_MANAGEMENT_ENTITY}/${GarageStatus.ACCEPTED}`}
                tableHeaders={tableHeadersApproved}
                disableSearch
                disableAdd
                onAdd={handleBlacklist}
                refreshData={refresh}
            />
        ),
        [GarageActiveTab.BLACK_LIST_REQUEST]: (
            <AbstractLanding
                headerName={''}
                addName={''}
                entityName={`${GARAGE_MANAGEMENT_ENTITY}/${GarageStatus.BLACKLISTED}`}
                tableHeaders={tableHeadersBlacklisted}
                disableSearch
                disableAdd
            />
        ),
    };

    const cardsData = [
        { title: 'Total Garages', key: 'acceptedGarages', icon: <HomeTwoTone /> },
        { title: 'Onboarding Requests', key: 'onboardingRequest', icon: <PhoneTwoTone /> },
        { title: 'Services Provided', key: 'totalServices', icon: <CustomerServiceTwoTone /> },
        { title: 'Blacklisted Garages', key: 'blacklistedGarages', icon: <StopTwoTone /> },
    ];

    const modalText = (
        <h3>
            Are you sure you want to Blacklist <span style={{ fontWeight: 'bold' }}>{garageName}</span>?
        </h3>
    );

    const handleConfirm = async () => {
        try {
            const res = await callApi(
                `${GARAGE_MANAGEMENT_ENTITY}/${garageBlackId}/${GarageStatus.BLACKLISTED}`,
                'PATCH',
            );

            if (res) {
                toastSuccess('Garage Blacklisted');
                setIsModal(false);
                setRefresh(true);
            }
        } catch (error) {
            throw new Error('Error accepting garage: ' + error);
        }
    };

    return (
        <div className='garageContainer'>
            <Row gutter={16} justify={'center'}>
                {cardsData.map((card) => (
                    <Col xs={24} sm={12} md={12} lg={6} xl={6} key={card.key}>
                        <Card bordered={true} style={{ backgroundColor: '#f3f3f3' }}>
                            <div style={{ display: 'flex', columnGap: '10px' }} className='garageCardContainer'>
                                <div className='iconsInGarage'>{card.icon}</div>{' '}
                                <div>
                                    <Typography.Title
                                        level={2}
                                        style={{ fontSize: '16px', fontWeight: '500', color: 'grey' }}>
                                        {card.title}
                                    </Typography.Title>
                                    <Typography.Text style={{ fontSize: '24px', fontWeight: '500', color: 'black' }}>
                                        {garageCardStat[card.key]}
                                    </Typography.Text>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            <div className='garageTitle'>
                <h2>Garage List</h2>
            </div>
            <div className='tabLinks'>
                <div className='links'>
                    {Object.entries(tabsConfig).map(([tabName]) => (
                        <p
                            key={tabName}
                            id={tabName.replace(/\s+/g, '')}
                            className={currentTab === tabName ? 'active' : ''}
                            onClick={() => setCurrentTab(tabName)}>
                            {tabName}
                        </p>
                    ))}
                </div>
            </div>
            <div className='tabBar'>{tabsConfig[currentTab]}</div>

            {isModal && (
                <ConfirmationModal
                    onclose={closeModal}
                    isOpen={isModal}
                    handleConfirmation={handleConfirm}
                    modalText={modalText}
                />
            )}
        </div>
    );
};

export default GarageManagement;
