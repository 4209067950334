/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import AbstractLanding from '../../pages/Entity/AbstractLanding';

import { staffData as initialStaffData } from '../../datamodels/Staff';
import { GARAGE_MANAGEMENT_ENTITY } from '../../constants/EntityName';
import { getTableHeaders } from '../../utils/forms/FormDataModel';

import './style.scss';
import { NextFrom } from '../../constants/AppConstant';

const Staff = () => {
    const [staffTabData, setStaffTabData] = useState(initialStaffData);

    const tableHeaders: any = getTableHeaders(staffTabData);

    const location = useLocation();
    const garageId = location.search.slice(1);

    return (
        <div className='staffContainer'>
            <AbstractLanding
                headerName={''}
                addName={''}
                entityName={`${GARAGE_MANAGEMENT_ENTITY}/application/${garageId}/${NextFrom.STAFF}`}
                tableHeaders={tableHeaders}
                disableSearch
                disableAdd
            />
        </div>
    );
};

export default Staff;
