import { serviceTagOption } from '../constants/AppConstant';
import { acceptableImageFormat } from '../constants/FilterDataValues';
import { errorMsg } from '../constants/messages';

const CategoryCategoriesFormData = [
    {
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'tag',
        label: 'Tag',
        fieldType: {
            name: 'select',
            options: serviceTagOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Tag' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'isFeature',
        label: 'Is Feature',
        placeholder: 'Click here to select an isFeature',
        fieldType: {
            name: 'select',
            options: [
                { name: 'YES', value: true },
                { name: 'NO', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Is Feature',
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'imageUrl',
        label: 'Icon Image',
        fieldType: {
            name: 'uploadFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { CategoryCategoriesFormData };
