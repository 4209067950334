import { errorMsg } from '../constants/messages';

const ServicePageFormData = [
    {
        title: 'Services',
        name: 'category',
        label: 'Category',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Category', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'details',
        label: 'Details',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Details' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { ServicePageFormData };
