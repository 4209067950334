import { DatePicker, Form } from 'antd';
// This date is may be used in future
import { subDays } from 'date-fns';

import { InputProps } from './TextInput';

import './style.scss';

const DateInput = (props: InputProps) => {
    const { formInput, index } = props;
    const { fieldType } = formInput;

    // This is for selected date for today and tomorrow date only.
    // This code is may be used in future
    // const isDateDisabled = (current: any) => {
    //     const showTomorrowDate = addDays(new Date(), 1);
    //     const showTodayDate = subDays(new Date(), 1);

    //     return !current || current.isAfter(showTomorrowDate) || current.isSameOrBefore(showTodayDate);
    // };

    // This is for user can selected only  date for today and future date.
    const isDateDisabled = (current: any) => {
        const showTodayDate = subDays(new Date(), 1);
        const selectedDate = new Date(current);

        // Enable today's date and future dates
        if (!current || selectedDate < showTodayDate) {
            return true;
        }

        return false;
    };

    return (
        <Form.Item
            key={index}
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            colon={formInput.required}
            labelAlign='left'
            name={formInput.name}
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
                ...((formInput.rules ? formInput.rules : []) as Array<any>),
            ]}>
            <DatePicker
                disabled={formInput.readonly}
                showTime={fieldType.showTime}
                style={{ width: '100%' }}
                format={fieldType.format}
                disabledDate={fieldType.specificDate && isDateDisabled}
            />
        </Form.Item>
    );
};

export default DateInput;
