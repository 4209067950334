/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getToken } from '../service/CookieManager';
import {
    GlobalActions,
    SERVICE_ID_DATA,
    SERVICE_IMAGE_DATA,
    SERVICE_LEVEL_DATA,
    SERVICE_LEVEL_DELETE_DATA,
    UPDATE_PROJECT_DETAILS,
    UPDATE_STORE,
    UPDATE_TOKEN,
} from './actions';

export interface IGlobalReducer {
    selectedProjectDetails: any;
    token: any;
    storeId: null | string;
    levelData: any;
    serviceIdData: any;
    imageUrl: any;
}

const initialState: IGlobalReducer = {
    selectedProjectDetails: {},
    token: getToken(),
    storeId: null,
    levelData: [],
    serviceIdData: {},
    imageUrl: [],
};

const globalReducer = (state = initialState, action: GlobalActions): IGlobalReducer => {
    switch (action.type) {
        case UPDATE_PROJECT_DETAILS:
            return {
                ...state,
                selectedProjectDetails: action.payload,
            };
        case UPDATE_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case UPDATE_STORE:
            return {
                ...state,
                storeId: action.storeId,
            };
        case SERVICE_LEVEL_DATA:
            return {
                ...state,
                levelData: [...state.levelData, ...action.levelData],
            };
        case SERVICE_LEVEL_DELETE_DATA:
            return {
                ...state,
                levelData: [...action.levelData],
            };

        case SERVICE_ID_DATA:
            return {
                ...state,
                serviceIdData: action.serviceIdData,
            };

        case SERVICE_IMAGE_DATA:
            return {
                ...state,
                imageUrl: [...state.imageUrl, ...action.imageUrl],
            };

        default:
            return state;
    }
};

export default globalReducer;
