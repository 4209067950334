import { Form } from 'antd';
import { useState } from 'react';
import ReactQuill from 'react-quill';

import { InputInterface } from '../utils/forms/FormInterface';

import 'react-quill/dist/quill.snow.css';

interface TextEditorProps {
    formInput: InputInterface;
    index: number;
}

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image'],
            ['clean'],
            ['code-block'],
        ],
    },
    clipboard: { matchVisual: false },
};

const TextEditor = (props: TextEditorProps) => {
    const { formInput, index } = props;
    const [value, setValue] = useState('');

    return (
        <Form.Item
            key={index}
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            colon={formInput.required}
            labelAlign='left'
            name={formInput.name}
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
            ]}>
            <ReactQuill theme='snow' modules={modules} value={value} onChange={setValue} />
        </Form.Item>
    );
};

export default TextEditor;
