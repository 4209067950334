import { useRef } from 'react';
import { Tooltip } from 'antd';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DraggableUploadList';

interface DraggableUploadListItemProps {
    originNode: any;
    file: any;
    fileList: any;
    moveRow: any;
}

const DraggableUploadListItem = (props: DraggableUploadListItemProps) => {
    const { originNode, file, fileList, moveRow } = props;

    const ref = useRef(null);
    const index = fileList.indexOf(file);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};

            if (dragIndex === index) {
                return {};
            }

            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item: any) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    const errorNode = <Tooltip title='Upload Error'>{originNode.props.children}</Tooltip>;

    return (
        <div
            ref={ref}
            className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
            style={{
                cursor: 'move',
            }}>
            {file.status === 'error' ? errorNode : originNode}
        </div>
    );
};

DraggableUploadListItem.defaultProps = {
    children: null,
};

export default DraggableUploadListItem;
