import { all, spawn } from 'redux-saga/effects';

import globalWatcherSaga from '../../pages/saga';

// watcher saga
function* rootSaga() {
    yield all([spawn(globalWatcherSaga)]);
}

export default rootSaga;
