import { useState } from 'react';
import { Button, Drawer } from 'antd';

import DrawerForm from './DrawerForm';

import './style.scss';

type FormInput = any;

interface DrawerInputProps {
    entityId?: string;
    formInput: FormInput;
    form?: any;
    // eslint-disable-next-line no-unused-vars
    onSuccessfulUpdate?: (id?: string) => void;
    isCreateMode?: boolean;
    entityName?: string;
    nestedEntityName?: string;
}

const DrawerInput = (props: DrawerInputProps) => {
    const { entityId, formInput, form, onSuccessfulUpdate, isCreateMode } = props;
    const { name, label, fieldType } = formInput;
    const { buttonText } = fieldType;

    const [isDrawerVisible, setDrawerVisible] = useState<boolean>(false);

    const updateOrderStatus = () => {
        onCloseDrawer();
    };

    const onCloseDrawer = () => {
        setDrawerVisible(false);
    };

    const openDrawer = () => {
        setDrawerVisible(true);
    };

    return (
        <>
            <div className='input-table-header' style={{ display: 'flex', justifyContent: 'center' }}>
                {!isCreateMode && (
                    <div className='addBtn'>
                        <Button onClick={() => openDrawer()}>{buttonText}</Button>
                    </div>
                )}
            </div>

            <Drawer
                width={420}
                placement='right'
                closable={true}
                bodyStyle={{ padding: '30px', height: '100%' }}
                headerStyle={{ paddingLeft: 12, paddingRight: 12 }}
                onClose={onCloseDrawer}
                visible={isDrawerVisible}
                maskClosable={false}>
                <DrawerForm
                    onSubmit={updateOrderStatus}
                    entityId={entityId}
                    onCloseDrawer={onCloseDrawer}
                    name={name}
                    label={label}
                    parentForm={form}
                    onSuccessfulUpdate={onSuccessfulUpdate}
                    fieldType={fieldType}
                />
            </Drawer>
        </>
    );
};

export default DrawerInput;
