import { all, takeLatest } from 'redux-saga/effects';

import { FETCH_PROJECT_DETAILS } from './actions';
// worker saga
export function* handleProjectDetailsUpdate() {
    // your code here
}

// watcher saga
function* globalWatcherSaga() {
    yield all([takeLatest(FETCH_PROJECT_DETAILS, handleProjectDetailsUpdate)]);
}

export default globalWatcherSaga;
