import React, { useRef } from 'react';
import { Button } from 'antd';

import './style.scss';

type GarageModalProps = {
    isOpen: boolean;
    onclose: () => void;
    modalText: any;
    handleConfirmation: () => void;
};

const ConfirmationModal: React.FC<GarageModalProps> = ({ isOpen, onclose, modalText, handleConfirmation }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const handleOverlayClick = (e: React.MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(e.target as Node) && onclose) {
            onclose();
        }
    };

    const handleCancel = () => {
        onclose();
    };

    return (
        <div className={`modalOverlay ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
            <div className='garageBlacklistModal' ref={modalRef}>
                <div className='confirmCard'>{modalText}</div>
                <div className='confirmButtons'>
                    <Button className='confirmBtn' onClick={handleConfirmation}>
                        OK
                    </Button>
                    <Button className='confirmBtn' onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
