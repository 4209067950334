/* eslint-disable no-unused-vars */
import { Button, Form, Input, InputNumber, Select, Space } from 'antd';
import { useHistory } from 'react-router-dom';

import callApi from '../Api/config';
import { toastWarning } from '../service/Toast';
import { getUpdatedFormData } from '../utils/functions';
import getDrawerFormOutgoingPayload, { PayloadFormatterKey } from '../utils/getDrawerFormOutgoingPayload';

const { Option } = Select;

type FieldType = {
    apiEndPoint: string;
    apiMethod: any;
    payloadFormatterKey?: PayloadFormatterKey;
    additionalData?: any;
    drawerFormOptions: string[];
    entityName?: string;
    formInitialValues?: { [key: string]: string };
    placeholder?: string;
};

interface DrawerFormProps {
    onSubmit: (payload: any) => void;
    entityId?: string;
    onCloseDrawer: () => void;
    name: string;
    label?: string;
    parentForm?: any;
    onSuccessfulUpdate?: (id?: string) => void;
    fieldType: FieldType;
}

const DrawerForm = (props: DrawerFormProps) => {
    const history = useHistory();
    const { entityId, onCloseDrawer, name, label, parentForm, onSuccessfulUpdate, fieldType } = props;
    const {
        apiMethod,
        apiEndPoint,
        additionalData,
        drawerFormOptions,
        entityName,
        payloadFormatterKey,
        formInitialValues,
        placeholder,
    } = fieldType;

    const [form] = Form.useForm();

    const initialValuesProp = { initialValues: formInitialValues || {} };

    const submitForm = async (values: any) => {
        const formattedPayload = getDrawerFormOutgoingPayload({
            name,
            payload: values,
            payloadFormatterKey,
            additionalData,
        });

        const updatedParentFormFields = getUpdatedFormData({
            name,
            values,
            additionalData,
            payloadFormatterKey,
            drawerFormOptions,
        });

        const res = await callApi(`${entityName}/${entityId}/${apiEndPoint}`, apiMethod, formattedPayload);
        if (res?.data?.isSuccess) {
            onCloseDrawer();
            parentForm.setFieldsValue(updatedParentFormFields);
            onSuccessfulUpdate?.();
            history.go(0);
        } else {
            toastWarning(res?.data?.error);
        }
    };

    return (
        <Form
            layout='vertical'
            autoComplete='off'
            form={form}
            onFinish={submitForm}
            {...initialValuesProp}
            scrollToFirstError>
            {drawerFormOptions && (
                <Form.Item name={name} label={label} rules={[{ required: true, message: 'required' }]}>
                    <Select showSearch placeholder={placeholder ?? `Select ${label}`} allowClear>
                        {drawerFormOptions.map((obj: any, index: number) => (
                            <Option key={index} value={obj.name}>
                                {obj.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {apiEndPoint === 'comments' && (
                <Form.Item label={label} name={name} rules={[{ required: true, message: 'required' }]}>
                    <Input type='text' />
                </Form.Item>
            )}

            {apiEndPoint === 'refunds' && (
                <Form.Item label={label} name={name} rules={[{ required: true, message: 'required' }]}>
                    <InputNumber />
                </Form.Item>
            )}
            <Form.Item>
                <Space>
                    <Button type='primary' htmlType='submit'>
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default DrawerForm;
