import { errorMsg } from '../constants/messages';

const staffData = [
    {
        name: 'firstName',
        label: 'First Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'First Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'lastName',
        label: 'Last Name',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Last Name' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'phone',
        label: 'Contact Number',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact Number' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email Id' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'userType',
        label: 'User Type',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Type' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'employeeType',
        label: 'Employee Type',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Employee Type' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { staffData };
