export type PayloadFormatterKey = 'delivery-partner';

type Arg = { name: string; payload: any; payloadFormatterKey: PayloadFormatterKey | undefined; additionalData: any };

const getDrawerFormOutgoingPayload = (arg: Arg) => {
    const { name, payload, payloadFormatterKey, additionalData } = arg;

    switch (payloadFormatterKey) {
        case 'delivery-partner': {
            const { slotDate, slotId } = additionalData;
            const { [name]: deliveryPartnerId } = payload;

            const formattedPayload = {
                slot: {
                    id: slotId,
                    date: slotDate,
                },
                deliveryPartner: {
                    id: deliveryPartnerId,
                },
            };

            return formattedPayload;
        }
        default: {
            return payload;
        }
    }
};

export default getDrawerFormOutgoingPayload;
