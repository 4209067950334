/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState } from 'react';

import { FilterFilled } from '@ant-design/icons';

import AbstractLanding from '../Entity/AbstractLanding';
import { pickUpFormData as initialFormData } from '../../datamodels/PickUpManagement';
import { activePickUpFormData as initialActiveFormData } from '../../datamodels/ActivePickup';
import { getTableHeaders } from '../../utils/forms/FormDataModel';
import { PICK_UP_ENTITY } from '../../constants/EntityName';
import ModalComponent from '../../components/ModalComponent';
import ThirdPartyForm from '../../components/ThirdPartyForm/ThirdPartyForm';
import FilterData from '../../components/FilterData';
import callApi from '../../Api/config';

import './style.scss';

const PickupActiveTab = {
    ACTIVE: 'Active Pickup',
    NEW: 'New Pickup',
};

const PickUpManagement = () => {
    const [currentTab, setCurrentTab] = useState<string>(PickupActiveTab.NEW);
    const [pickUpFormData, setPickUpFormData] = useState(initialFormData);
    const [activePickUpFormData, setActivePickUpFormData] = useState(initialActiveFormData);
    const [refreshData, setRefreshData] = useState(false);

    const [isModal, setIsModal] = useState<boolean>(false);
    const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
    const tableHeaders: any = getTableHeaders(pickUpFormData);
    const tableHeadersActive: any = getTableHeaders(activePickUpFormData);

    const [vehiclePickupId, setVehiclePickupId] = useState<string>('');
    const [statusFilter, setStatusFilter] = useState<any>(null);
    const [pickupDate, setPickupDate] = useState<string>('');
    const [timeSlot, setTimeSlot] = useState<string>('');

    let holdSelectEvent: any = null;

    const handleStatusFilter = (values: any) => {
        setStatusFilter(values);
        setIsFilterModal(false);
    };

    const handleModal = () => {
        setIsModal(true);
    };

    const onAddNew = (argument1: any) => {
        setPickupDate(argument1.date);
        setTimeSlot(argument1?.pickupTimeSlot);
        setVehiclePickupId(argument1.bookingId);
        setIsModal(true);
        setRefreshData(false);
    };

    const handleFilterModal = () => {
        setIsFilterModal(true);
    };

    function onSelect(e: any) {
        holdSelectEvent = e;
    }

    const updateStatus = async (payload: any) => {
        const res = await callApi(`${PICK_UP_ENTITY}/order/${payload.thirdPartyId}/${payload.status}`, 'PATCH');
        setRefreshData((prev) => !prev);
        setRefreshData;
    };

    const getClickRecordColom = (record: any) => {
        const payload: any = { ...record };
        if (holdSelectEvent) {
            payload.status = holdSelectEvent.target.value;
            updateStatus(payload);
        }
    };

    return (
        <div>
            <div className='pickUpTitle'>
                <h2>{currentTab} List</h2>
            </div>
            <div className='tabLinks'>
                <div className='links'>
                    <p
                        id='newPickLink'
                        className={currentTab === PickupActiveTab.NEW ? 'active' : ''}
                        onClick={() => setCurrentTab(PickupActiveTab.NEW)}>
                        New Pickup
                    </p>
                    <p
                        id='activePickLink'
                        className={currentTab === PickupActiveTab.ACTIVE ? 'active' : ''}
                        onClick={() => setCurrentTab(PickupActiveTab.ACTIVE)}>
                        Active Pickup
                    </p>
                </div>
                {currentTab === PickupActiveTab.ACTIVE && (
                    <div className='filter' id='filter' onClick={handleFilterModal}>
                        <FilterFilled />
                        <p>Apply Filter</p>
                    </div>
                )}
            </div>
            {currentTab === PickupActiveTab.NEW ? (
                <div className='pickUpActionBar'>
                    <AbstractLanding
                        headerName={''}
                        addName={''}
                        entityName={PICK_UP_ENTITY}
                        tableHeaders={tableHeaders}
                        onAdd={onAddNew}
                        disableSearch
                        disableAdd
                        handleModal={handleModal}
                        refreshData={refreshData}
                    />
                </div>
            ) : (
                <div className='pickUpActionBar'>
                    <AbstractLanding
                        headerName={''}
                        addName={''}
                        entityName={
                            statusFilter ? PICK_UP_ENTITY + `/pickup/${statusFilter}` : PICK_UP_ENTITY + `/pickup`
                        }
                        tableHeaders={tableHeadersActive}
                        disableSearch
                        disableAdd
                        onSelect={onSelect}
                        onAdd={getClickRecordColom}
                        refreshData={refreshData}
                    />
                </div>
            )}
            <ModalComponent openModal={isModal} closeModal={() => setIsModal(false)}>
                <ThirdPartyForm
                    id={vehiclePickupId}
                    setIsModal={setIsModal}
                    timeSlot={timeSlot}
                    pickupDate={pickupDate}
                    refreshPage={setRefreshData}
                />
            </ModalComponent>
            <ModalComponent openModal={isFilterModal} closeModal={() => setIsFilterModal(false)}>
                <FilterData handleStatusFilter={handleStatusFilter} />
            </ModalComponent>
        </div>
    );
};

export default PickUpManagement;
