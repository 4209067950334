import { errorMsg } from '../../constants/messages';

const FormPackageDocumentsFormData = [
    {
        title: 'Documents Details',
        name: 'name',
        label: 'Document Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Documents Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'maxFiles',
        label: 'Max Files',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Max Files' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { FormPackageDocumentsFormData };
