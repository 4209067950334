import { Form } from 'antd';

import DragAndDropTable from './DragAndDropTable';

import './style.scss';

interface TableInputProps {
    index: number;
    name: string;
    formTemplate: any;
    form: any;
    value?: any;
    entityId?: string;
}

const TableInput = (props: TableInputProps) => {
    const { formTemplate, index } = props;

    const tableData = props.form.getFieldValue(props.name);

    return (
        <Form.Item
            key={index}
            // label={<div style={{ fontSize: '16px' }}>{formTemplate.label}</div>}
            name={formTemplate.name}
            colon={false}
            rules={[
                {
                    required: formTemplate.required,
                    message: formTemplate.errorMsg,
                },
            ]}>
            <div className='input-table-header'>
                <div>
                    <div className='heading'>
                        Total {formTemplate.label} added: {tableData?.length || '0'}
                    </div>
                </div>
            </div>

            <div className='table-btn-container btn1'>
                {tableData?.length !== 0 && (
                    <DragAndDropTable
                        formTemplate={props.formTemplate}
                        tableData={tableData}
                        entityId={props.entityId}
                    />
                )}
            </div>
        </Form.Item>
    );
};

export default TableInput;
