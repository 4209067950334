import { SwapRightOutlined } from '@ant-design/icons';

function getSlotTimeRange(slot: { id: number; slotStartTime: number; slotEndTime: number }) {
    const startHours = Math.floor(slot.slotStartTime / 60);
    const startMinutes = Math.floor(slot.slotStartTime % 60);
    const endHours = Math.floor(slot.slotEndTime / 60);
    const endMinutes = Math.floor(slot.slotEndTime % 60);

    const startPeriod = startHours >= 12 ? 'PM' : 'AM';
    const endPeriod = endHours >= 12 ? 'PM' : 'AM';

    const getFormattedString = (number: number) => (String(number).length === 1 ? `0${number}` : String(number));

    const formattedStartHours = startHours % 12 === 0 ? 12 : startHours % 12;
    const formattedEndHours = endHours % 12 === 0 ? 12 : endHours % 12;

    return (
        <div
            style={{
                display: 'grid',
                justifyContent: 'center',
                gridTemplateColumns: '1fr 1fr 1fr',
                alignItems: 'center',
                columnGap: '10px',
                marginBottom: '5px',
                padding: '8px 10px',
                width: '100%',
                maxWidth: '970px',
                height: 'auto',
                backgroundColor: '#f8fafb',
                border: '2px solid #d5d7da',
                borderRadius: '5px',
                fontSize: '14px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}>
            <p>{`${getFormattedString(formattedStartHours)}:${getFormattedString(startMinutes)} ${startPeriod}`}</p>
            <p>
                <SwapRightOutlined />
            </p>
            <p>{`${getFormattedString(formattedEndHours)}:${getFormattedString(endMinutes)} ${endPeriod}`}</p>
        </div>
    );
}

function formatSlotCreateAtDateTime(slot: { id: number; createdAt: number }) {
    const options: any = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    return new Date(slot.createdAt).toLocaleString('en-US', options);
}

function formatSlotUpdatedAtDateTime(slot: { id: number; updatedAt: number }) {
    const options: any = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    return new Date(slot.updatedAt).toLocaleString('en-US', options);
}

export { getSlotTimeRange, formatSlotCreateAtDateTime, formatSlotUpdatedAtDateTime };
