/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { Form, Select } from 'antd';

import { errorMsg } from '../constants/messages';
import { InputInterface } from '../utils/forms/FormInterface';

const { Option } = Select;

import './style.scss';
// import debounceFunction from '../utils/debounce/Debounce';

interface SelectInputProps {
    formInput: InputInterface;
    setFormData?: any;
    form: any;
    index: number;
    isCreateMode?: boolean;
}

const DynamicSelectInput = (props: SelectInputProps) => {
    const { formInput, setFormData, form, index, isCreateMode } = props;
    const {
        fieldType: { options },
    } = formInput;

    const currentItemId = form.getFieldValue(formInput.name);

    /**
     * this function updates the list of form fields to be shown based on the item that is selected from the dropdown menu of items
     */
    const updateOptions = useCallback((optionTypeArray?: any, setFormData?: any) => {
        optionTypeArray &&
            setFormData?.((formData: any) => [
                ...formData.filter((formDatum: any) => !formDatum.addedDynamically),
                {
                    addedDynamically: true,
                    name: `optionType-${optionTypeArray.displayName}`,
                    label: optionTypeArray.displayName,
                    fieldType: {
                        name: 'select',
                        options: optionTypeArray.options.map((option: any) => ({
                            value: option.id,
                            name: option.displayName,
                        })),
                    },
                    readonly: !isCreateMode,
                    required: true,
                    errorMsg: errorMsg,
                    placeholder: `Click to select a ${optionTypeArray.displayName.toLowerCase()}`,
                    tableMeta: { title: 'test', enableSort: false, allowAddRecordClick: false },
                    isTableHeader: false,
                    isAccordian: isCreateMode ? true : false,
                    default: '',
                },
            ]);
    }, []);

    /**
     * From this useEffect the above defined function to update the input form fields is called on the initial render
     */
    useEffect(() => {
        const item = options.find((option: { value: number }) => option.value === currentItemId);
        const optionTypeArray = item?.additionalData;

        updateOptions(optionTypeArray, setFormData);
    }, [currentItemId, options, setFormData, updateOptions]);

    /**
     * This function updates the form fields when the item is changed in the dropdown
     */
    const changeHandler = (value: any, record: any) => {
        const { additionaldata: optionTypeArray } = record || {};

        updateOptions(optionTypeArray, setFormData);
    };

    /**
     * This function applies a debounce to the onChange handler of the select component in order to improve search performance
     */
    // Define the debounced search function separately
    // const debouncedSearch = (value: string, options: any[], formInput: any, form: any) => {
    //     const filteredOptions = options.filter(
    //         (option: any) => option.name.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    //     );
    //     formInput.fieldType.options = filteredOptions;

    //     // Trigger a re-render to update the select options
    //     form.setFieldsValue({
    //         [formInput.name]: undefined,
    //     });
    // };

    // Create a memoized function that wraps the debounced function using useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const handleSearch = useCallback(debounceFunction(debouncedSearch, 500), []);

    return (
        <Form.Item
            key={index}
            label={formInput.label}
            name={formInput.name}
            className='text'
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            labelAlign='left'
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
            ]}>
            <Select
                showSearch
                allowClear
                optionFilterProp='children'
                onChange={changeHandler}
                placeholder={formInput.placeholder}
                disabled={formInput.readonly}
                style={{ width: '100%' }}>
                {formInput.fieldType.options?.map((obj: any, index: number) => (
                    <Option key={index} value={obj.value} additionaldata={obj.additionalData}>
                        {obj.name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default DynamicSelectInput;
