/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import './dropdown.scss';
import { ORDER_LIST_ENTITY } from '../../constants/EntityName';

interface DropdownProps {
    filterName: string;
    filterKey: string;
    checkBoxOptions: string[];
    onSubmitFilter: (filterKey: string, list: CheckboxValueType[]) => void;
    width?: any;
    modalWidth?: any;
}

const CheckboxGroup = Checkbox.Group;

const Dropdown = (props: DropdownProps) => {
    const { checkBoxOptions, filterName, filterKey, onSubmitFilter } = props;

    const valueRef = useRef<CheckboxValueType[]>([]);

    const history = useHistory();
    const savedOrderStatus: any = localStorage.getItem('orderStatus');

    const [selectedValue, setSelectedValue] = useState<any>(savedOrderStatus);

    // make a function and reset savedOrderStatus in setSelectedValue :
    const resetSavedOrderStatus = () => {
        localStorage.removeItem('orderStatus');
        setSelectedValue(valueRef.current);
    };

    const [show, setShow] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const onClickSetShow = () => {
        setShow((show) => !show);
    };

    const onSelect = (value: any) => {
        if (value.includes('Business Insights')) {
            valueRef.current = [...value, 'Business_Insights'];
            setSelectedValue(value);
        } else if (value.includes('ALL MEMBERS')) {
            valueRef.current = [...value, 'ALL_MEMBERS'];
            setSelectedValue(value);
        } else {
            valueRef.current = value;
            setSelectedValue(value);
        }
    };

    const clearAllChecked = () => {
        setSelectedValue([]);
        valueRef.current = [];
        onClickSetShow();
    };

    const onSubmit = () => {
        onSubmitFilter(filterKey, valueRef.current);
        onClickSetShow();
    };

    useEffect(() => {
        const handleClickOutSide = (e: MouseEvent) => {
            if (show && ref.current && !ref.current.contains(e.target as Node)) {
                setShow(false);
                const isEqual = JSON.stringify(valueRef.current) === JSON.stringify(selectedValue);
                if (!isEqual) {
                    onSubmitFilter(filterKey, valueRef.current);
                }
                if (history.location.pathname === ORDER_LIST_ENTITY) {
                    history.push(ORDER_LIST_ENTITY);
                }
                resetSavedOrderStatus();
            }
        };
        document.addEventListener('click', handleClickOutSide);
        return () => {
            document.removeEventListener('click', handleClickOutSide);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <div ref={ref} className='dropdown-wrapper'>
            <Button
                style={{ width: props.width ? props.width : '100%' }}
                className={`dropdown-btn ${selectedValue && ''}`}
                size={'large'}
                onClick={onClickSetShow}>
                {filterName}
                <DownOutlined />
            </Button>

            {show ? (
                <div className='checkbox-wrapper' style={{ width: props.modalWidth ? props.modalWidth : '170px' }}>
                    <CheckboxGroup options={checkBoxOptions} value={selectedValue} onChange={onSelect} />
                    <hr />
                    <div className='clear-btn-wrapper'>
                        <button className='clear-btn' onClick={clearAllChecked}>
                            Clear
                        </button>

                        <button className='clear-btn' onClick={onSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
export default Dropdown;
