import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { Form, FormInstance, Select } from 'antd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { InputInterface } from '../utils/forms/FormInterface';
import useDragDrop from '../utils/useDragDrop';

import './style.scss';

const { Option } = Select;

interface SelectInputProps {
    formInput: InputInterface;
    index: number;
    form: FormInstance;
    isCreateMode?: boolean;
}

const MultiSelectInput = (props: SelectInputProps) => {
    const { formInput, index, form, isCreateMode } = props;
    const { dragDrop, options } = formInput.fieldType;
    const fieldValue = form.getFieldValue(formInput.name);

    const defaultValue = options?.filter((option: any) => fieldValue?.includes(option.name));

    const labelByValues = options.reduce(
        (acc: { [key: string]: string }, option: { name: string; value: string }) => ({
            ...acc,
            [option.value]: option.name,
        }),
        {},
    );

    const [, setDragDropValues] = useState<any>([]);

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Form.Item
                    key={index}
                    label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
                    name={formInput.name}
                    className='text'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        mode='multiple'
                        placeholder={formInput.placeholder}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        style={{ width: '100%' }}
                        disabled={formInput.readonly && !isCreateMode}
                        defaultValue={defaultValue}
                        onChange={setDragDropValues}>
                        {formInput.fieldType.options.map((obj: any, index: number) => (
                            <Option key={index} value={obj.value} selected>
                                {obj.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {dragDrop && (
                    <Form.Item
                        label={<span className='labelWithOffset dragDroplabel'>Drag options to change the order</span>}
                        labelAlign='left'
                        colon={false}
                        wrapperCol={{ span: 8, offset: 4 }}
                        labelCol={{ span: 4 }}>
                        <div className='dragDropContainer'>
                            {fieldValue.map((value: string, index: number) => (
                                <Tag
                                    key={value}
                                    label={labelByValues[value]}
                                    index={index}
                                    form={form}
                                    formInput={formInput}
                                    setDragDropValues={setDragDropValues}
                                />
                            ))}
                        </div>
                    </Form.Item>
                )}
            </DndProvider>
        </>
    );
};

const Tag = (props: any) => {
    const { label, index, form, formInput, setDragDropValues } = props;

    const ref = useDragDrop(form, index, formInput, setDragDropValues);

    return (
        <div ref={ref}>
            <span className='tag'>{label}</span>
        </div>
    );
};

export default MultiSelectInput;
