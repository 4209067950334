// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serviceCategoryCardContainer {
  width: 100%;
  height: 200px;
  border: 1px solid #b3b0b0;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px; }
  .serviceCategoryCardContainer .categoryName {
    width: 40%; }
  .serviceCategoryCardContainer .subCategoryImgCardContainer {
    display: flex;
    width: 40%;
    justify-content: space-between;
    flex-wrap: wrap; }
    .serviceCategoryCardContainer .subCategoryImgCardContainer .subCategoryImgCard {
      border: 1px solid #b1b0b0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 10px 0;
      padding: 10px;
      column-gap: 10px; }
      .serviceCategoryCardContainer .subCategoryImgCardContainer .subCategoryImgCard p {
        margin-bottom: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/ServiceCategoryCard/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,aAAa;EACb,yBAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,mBAAmB,EAAA;EARvB;IAWQ,UAAU,EAAA;EAXlB;IAcQ,aAAa;IACb,UAAU;IACV,8BAA8B;IAC9B,eAAe,EAAA;IAjBvB;MAsBY,yBAAoC;MACpC,aAAa;MACb,6BAA6B;MAC7B,mBAAmB;MACnB,cAAc;MACd,aAAa;MACb,gBAAgB,EAAA;MA5B5B;QA+BgB,gBAAgB,EAAA","sourcesContent":[".serviceCategoryCardContainer {\n    width: 100%;\n    height: 200px;\n    border: 1px solid rgb(179, 176, 176);\n    border-radius: 5px;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    margin-bottom: 10px;\n\n    .categoryName {\n        width: 40%;\n    }\n    .subCategoryImgCardContainer {\n        display: flex;\n        width: 40%;\n        justify-content: space-between;\n        flex-wrap: wrap;\n\n        .subCategoryImgCard {\n            // width: 150px;\n            // height: 50px;\n            border: 1px solid rgb(177, 176, 176);\n            display: flex;\n            justify-content: space-evenly;\n            align-items: center;\n            margin: 10px 0;\n            padding: 10px;\n            column-gap: 10px;\n\n            p {\n                margin-bottom: 0;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
