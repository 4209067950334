import { errorMsg } from '../../constants/messages';

const blacklistedGaragesData = [
    {
        name: 'garageId',
        label: 'Garage Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Garage Id' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'garageName',
        label: 'Garage Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Garage Name', enableSort: false, allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'customerInfo',
        label: 'Customer Info',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Customer Info' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'fullAddress',
        label: 'Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Address' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'createdAt',
        label: 'Blacklist Date',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Blacklist Date' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { blacklistedGaragesData };
