import moment from 'moment';

import {
    USERS_ENTITY,
    SUBCATEGORIES_ENTITY,
    CATEGORIES_ENTITY,
    DEFECT_TYPES_ENTITY,
    ORDER_LIST_ENTITY,
    COMPLAINTS_ENTITY,
    MANAGER_TECHNICIAN_ENTITY,
    ASSETS_ENTITY,
    LOCATIONS_ENTITY,
    CONTACT_INFO,
    APPLICATION_FORM_QUESTIONS,
    APPLICATION_FORM_PACKAGE_PLANS,
    APPLICATION_FORM_SERVICES,
    APPLICATION_FORM_PACKAGES,
    SERVICE_PROCESS_STEPS,
    SERVICE_PROCESS_DETAILS,
    SERVICE_PROCESS_SUBTITLE,
    MODELS_ENTITY,
    CATEGORY_SUBCATEGORIES_ENTITY,
    COUPONS_ENTITY,
    GARAGE_COMMISSION_TYPE,
    USERS_LIST_ENTITY,
} from '../constants/EntityName';

export const positionSort = (firstEntity: { position: number }, secondEntity: { position: number }) =>
    firstEntity.position - secondEntity.position;

export const getIncomingPayload = (entityName: string, apiData: any) => {
    switch (entityName) {
        case USERS_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                // if (key === 'role') {
                //     return { ...acc, [key]: value.id };
                // }

                if (key === 'password') {
                    return { ...acc, [key]: '*****' };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case MODELS_ENTITY: {
            return {
                ...apiData,
                make: apiData?.make?.id,
            };
        }

        case CATEGORY_SUBCATEGORIES_ENTITY: {
            return {
                ...apiData,
                category: apiData?.category?.id,
            };
        }

        case APPLICATION_FORM_QUESTIONS: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'service') {
                    return { ...acc, [key]: value?.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case DEFECT_TYPES_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'subcategory') {
                    return { ...acc, [key]: value.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case LOCATIONS_ENTITY:
        case ASSETS_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: value.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case CATEGORIES_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'asset') {
                    return { ...acc, [key]: value?.id };
                }
                if (key === 'manager') {
                    return { ...acc, [key]: value?.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case SUBCATEGORIES_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'category') {
                    return { ...acc, [key]: value?.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case COMPLAINTS_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'defectType') {
                    return { ...acc, [key]: value?.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_SERVICES: {
            return {
                ...apiData,
                parameters: apiData?.parameters?.map((parameter: { id: string }) => parameter.id),
            };
        }

        case APPLICATION_FORM_PACKAGES: {
            return {
                ...apiData,
                service: apiData?.service?.id,
                plans: apiData?.plans?.map((plans: { id: string }) => plans.id),
                serviceAttributes: apiData?.serviceAttributes?.map(
                    (serviceAttributes: { id: string }) => serviceAttributes.id,
                ),
                documitraFees: apiData?.documitraFees ? Number(apiData.documitraFees) : undefined,
                governmentFees: apiData?.governmentFees ? Number(apiData.governmentFees) : undefined,
            };
        }

        case APPLICATION_FORM_PACKAGE_PLANS: {
            return {
                ...apiData,
                features: apiData?.features?.map((feature: { id: string }) => feature.id),
                documents: apiData?.documents?.map((document: { id: string }) => document.id),
                price: apiData?.price ? Number(apiData.price) : undefined,
            };
        }
        case COUPONS_ENTITY: {
            return {
                ...apiData,
                validFrom: apiData.validFrom ? moment(apiData.validFrom, 'YYYY-MM-DD') : '',
                validTo: apiData.validTo ? moment(apiData.validTo, 'YYYY-MM-DD') : '',
            };
        }

        case SERVICE_PROCESS_DETAILS:
        case SERVICE_PROCESS_STEPS: {
            return {
                ...apiData,
                service: apiData?.service?.id,
            };
        }

        case SERVICE_PROCESS_SUBTITLE: {
            return {
                ...apiData,
                title: apiData?.title?.id,
            };
        }

        case GARAGE_COMMISSION_TYPE: {
            return {
                ...apiData,
                commission: apiData?.id,
                garageName: apiData?.garage?.id,
            };
        }

        case USERS_LIST_ENTITY: {
            return {
                ...apiData,
                phone: `+${apiData.countryCode} - ${apiData.phone}`,
            };
        }

        case MANAGER_TECHNICIAN_ENTITY: {
            return {
                manager: apiData?.results?.id,
                technicians: apiData?.results?.technicians?.map((technicians: { id: string }) => technicians.id),
            };
        }

        case CONTACT_INFO: {
            return {
                ...apiData,
                facebook: apiData?.socialMediaLinks?.facebook,
                twitter: apiData?.socialMediaLinks?.twitter,
                email: apiData?.email,
            };
        }

        case ORDER_LIST_ENTITY: {
            return Object.keys(apiData).reduce((acc, key) => {
                if (key === 'store') {
                    return {
                        ...acc,
                        store: apiData[key]?.name,
                        storePhone: apiData[key]?.phone,
                        line1: apiData[key]?.line1,
                        line2: apiData[key]?.line2,
                        state: apiData[key]?.state,
                        city: apiData[key]?.city,
                        latitude: apiData[key]?.latitude,
                        longitude: apiData[key]?.longitude,
                        landmark: apiData[key]?.landmark,
                        storePincode: apiData[key]?.storePincode,
                    };
                }

                if (key === 'address') {
                    return {
                        ...acc,
                        addressType: apiData[key]?.addressType,
                        customerLine1: apiData[key]?.line1,
                        customerLine2: apiData[key]?.line2,
                        streetName: apiData[key]?.streetName,
                        customerLatitude: apiData[key]?.latitude,
                        customerLongitude: apiData[key]?.longitude,
                        customerLandmark: apiData[key]?.landmark,
                        pincode: apiData[key]?.pincode,
                    };
                }
                if (key === 'customer') {
                    return {
                        ...acc,
                        id: apiData[key].id,
                        name: apiData[key].name,
                        email: apiData[key].email,
                        phone: apiData[key].phone,
                        referralCode: apiData[key].referralCode,
                    };
                }

                if (key === 'slots') {
                    const variants = apiData[key]?.reduce((acc: any, slot: any) => [...acc, ...slot.variants], []);

                    const addons = variants?.reduce(
                        (acc: any, variant: any) => [
                            ...acc,
                            ...variant.addons.map((addon: any) => ({
                                ...addon,
                                variantSlug: variant.variantSlug,
                            })),
                        ],
                        [],
                    );

                    const bulkAddons = apiData[key].reduce((acc: any, currentValue: any) => {
                        currentValue.variants.forEach((variant: any) => {
                            if (variant.bulkAddon && Object.keys(variant.bulkAddon).length !== 0) {
                                acc.push(variant.bulkAddon);
                            }
                        });
                        return acc;
                    }, []);

                    // console.log('lol', { variants, addons, bulkAddons, data: apiData[key] });

                    return {
                        ...acc,
                        slots: apiData[key],
                        addons,
                        variants,
                        bulkAddons,
                    };
                }

                return { ...acc, [key]: apiData[key] };
            }, {});
        }
    }

    const entityNamesArray = entityName?.split('/');
    const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];

    switch (`/${requiredEntityName}`) {
        default:
            return apiData;
    }
};
