import { errorMsg } from '../../constants/messages';

const FormQuestionFormData = [
    {
        title: 'Question Details',
        name: 'questionText',
        label: 'Question',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Question', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'service',
        label: 'Service',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Service',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Service',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'serviceName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'isRequired',
        label: 'isRequired',
        placeholder: 'isRequired',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        tableMeta: { title: 'isRequired', columnType: 'active' },
        errorMsg: 'required',
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'sequence',
        label: 'Sequence',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Sequence' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'applicableTo',
        label: 'applicableTo',
        placeholder: 'applicableTo',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Adults', value: 'adults' },
                { name: 'Minors', value: 'minors' },
                { name: 'Both', value: 'both' },
            ],
        },
        required: true,
        tableMeta: { title: 'applicableTo' },
        errorMsg: 'required',
        isTableHeader: true,
        isAccordian: true,
    },
];

export { FormQuestionFormData };
