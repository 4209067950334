// import { fuelTypeOption } from '../constants/AppConstant';
// import { acceptableImageFormat } from '../constants/FilterDataValues';
import { acceptableImageFormat } from '../constants/FilterDataValues';
import { errorMsg } from '../constants/messages';

const CategorySubcategoriesFormData = [
    {
        title: 'Subcategories Details',
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'category',
        label: 'category',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an category',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'category',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'categoryName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'imageUrl',
        label: 'Image Url',
        fieldType: {
            name: 'uploadFile',
            accept: acceptableImageFormat,
            maxCount: 1,
            allowImageCropping: true,
        },
        placeholder: 'Click here to select image',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image Url', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { CategorySubcategoriesFormData };
