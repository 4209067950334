// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.collapse1 .ant-collapse {
  border: none; }

.collapse1 .ant-collapse-item-active {
  border: none !important; }

.collapse1 .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px !important;
  color: #637f92 !important; }

.collapse1 .ant-collapse-header {
  height: 40px !important;
  background: #f0f4f7 !important;
  border: none !important; }

.collapse1 .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  border: 1px solid #637f92;
  background: #637f92;
  border-radius: 50%;
  color: white; }

.ant-collapse-content {
  border: none !important; }
`, "",{"version":3,"sources":["webpack://./src/components/Accordian/Accordian.scss"],"names":[],"mappings":"AAAA;EACI,SAAS;EACT,UAAU;EACV,sBAAsB,EAAA;;AAG1B;EAEQ,YAAY,EAAA;;AAFpB;EAMQ,uBAAuB,EAAA;;AAN/B;EAUQ,gBAAgB;EAChB,eAAe;EACf,4BAA4B;EAC5B,yBAAyB,EAAA;;AAbjC;EAiBQ,uBAAuB;EACvB,8BAA8B;EAC9B,uBAAuB,EAAA;;AAnB/B;EAuBQ,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY,EAAA;;AAIpB;EACI,uBAAuB,EAAA","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.collapse1 {\n    .ant-collapse {\n        border: none;\n    }\n\n    .ant-collapse-item-active {\n        border: none !important;\n    }\n\n    .ant-collapse > .ant-collapse-item > .ant-collapse-header {\n        font-weight: 600;\n        font-size: 16px;\n        line-height: 16px !important;\n        color: #637f92 !important;\n    }\n\n    .ant-collapse-header {\n        height: 40px !important;\n        background: #f0f4f7 !important;\n        border: none !important;\n    }\n\n    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {\n        border: 1px solid #637f92;\n        background: #637f92;\n        border-radius: 50%;\n        color: white;\n    }\n}\n\n.ant-collapse-content {\n    border: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
