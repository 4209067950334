import { errorMsg } from '../../constants/messages';

const PackagePlansFormData = [
    {
        title: 'Package Plan Details',
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'price',
        label: 'Price ($)',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: 'Price should be in number',
        tableMeta: { title: 'Price ($)' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'features',
        label: 'Features',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select an Features',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Features',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'featureDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'documents',
        label: 'Document',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select an Document',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Document',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'documentsDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
];

export { PackagePlansFormData };
