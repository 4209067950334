import { acceptableImageFormat } from '../constants/FilterDataValues';
import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_RATING } from '../utils/Regexp/Regex';

const ReviewsFormData = [
    {
        title: 'Faq Details',
        name: 'clientName',
        label: 'Client Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Client Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'rating',
        label: 'Rating (Range 1 to 5)',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [{ pattern: REGEX_RATING, message: customErrorMsg('reviews') }],
        errorMsg: errorMsg,
        tableMeta: { title: 'Rating' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'reviewContent',
        label: 'Review Content',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Review Content' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'iconImage',
        label: 'Icon Image',
        fieldType: {
            name: 'documitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
];

export { ReviewsFormData };
