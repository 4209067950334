/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SERVICE_CREATE_ENTITY } from '../../constants/EntityName';
import callApi from '../../Api/config';

import './style.scss';

const ServiceCategoryCard = () => {
    const [imgAndTitleData, setImgAndTitleData] = useState([]);

    const { globalReducer } = useSelector((state: any) => state);

    const garageId = globalReducer.serviceIdData.garageId;

    useEffect(() => {
        const fetchServiceCardData = async () => {
            if (garageId) {
                try {
                    const res = await callApi(`${SERVICE_CREATE_ENTITY}/${garageId}`, 'GET');
                    setImgAndTitleData(res?.data?.data);
                } catch (error) {
                    console.error('Error fetching service card data:', error);
                }
            }
        };

        fetchServiceCardData();
    }, [garageId]);

    return (
        <div>
            {imgAndTitleData.map((item: any, index: any) => (
                <div className='serviceCategoryCardContainer' key={index}>
                    <div className='categoryName'>
                        <h3>{item.category.serviceName}</h3>
                        <p>View details for services which come under Comprehensive Category</p>
                    </div>
                    <div className='subCategoryImgCardContainer'>
                        {item.category.subCategory.map((subCategory: any, subIndex: any) => (
                            <div key={subIndex} className='subCategoryImgCard'>
                                <img
                                    src={subCategory.subcategotyImage}
                                    alt={subCategory.name}
                                    style={{ width: '50px', height: '35px', objectFit: 'contain' }}
                                />
                                <p>{subCategory.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ServiceCategoryCard;
