import React from 'react';
import './Accordian.scss';

import { Collapse } from 'antd';

const { Panel } = Collapse;

interface AccordianProps {
    title?: string;
    children?: React.ReactNode;
}

const Accordian = (props: AccordianProps) => (
    <div className='collapse1'>
        <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
            <Panel header={props.title} key='1'>
                {props.children}
            </Panel>
        </Collapse>
    </div>
);
export default Accordian;
