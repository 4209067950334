/* eslint-disable @typescript-eslint/no-explicit-any */
export const UPDATE_PROJECT_DETAILS = 'UPDATE_PROJECT_DETAILS';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_STORE = 'UPDATE_STORE';
export const FETCH_PROJECT_DETAILS = 'FETCH_PROJECT_DETAILS';
export const FETCH_INVOICE_DETAILS = 'FETCH_INVOICE_DETAILS';
export const SERVICE_LEVEL_DATA = 'SERVICE_LEVEL_DATA';
export const SERVICE_ID_DATA = 'SERVICE_ID_DATA';
export const SERVICE_LEVEL_DELETE_DATA = 'SERVICE_LEVEL_DELETE_DATA';
export const SERVICE_IMAGE_DATA = 'SERVICE_IMAGE_DATA';
export const SERVICE_SUBCATEGORY_DATA = 'SERVICE_SUBCATEGORY_DATA';

// Action Type .......................

interface IUpdateTokenAction {
    type: typeof UPDATE_TOKEN;
    token: string;
}
interface IUpdateStoreAction {
    type: typeof UPDATE_STORE;
    storeId: string;
}

interface IUpdateServiceLevelAction {
    type: typeof SERVICE_LEVEL_DATA;
    levelData: any;
}

interface IUpdateServiceIdAction {
    type: typeof SERVICE_ID_DATA;
    serviceIdData: any;
}

interface IUpdateServiceDeleteAction {
    type: typeof SERVICE_LEVEL_DELETE_DATA;
    levelData: any;
}

interface IUpdateServiceImageAction {
    type: typeof SERVICE_IMAGE_DATA;
    imageUrl: any;
}

const updateServiceImageAction = (imageUrl: any): GlobalActions => ({
    type: SERVICE_IMAGE_DATA,
    imageUrl,
});

const updateTokensAction = (token: string): GlobalActions => ({
    type: UPDATE_TOKEN,
    token,
});

const updateStoreAction = (storeId: string): GlobalActions => ({
    type: UPDATE_STORE,
    storeId,
});

const updateServiceLevelAction = (levelData: any): GlobalActions => ({
    type: SERVICE_LEVEL_DATA,
    levelData,
});

const updateServiceIdAction = (serviceIdData: any): GlobalActions => ({
    type: SERVICE_ID_DATA,
    serviceIdData,
});

const updateServiceDeleteAction = (levelData: any): GlobalActions => ({
    type: SERVICE_LEVEL_DELETE_DATA,
    levelData,
});

interface IUpdateProjectdetailsAction {
    type: typeof UPDATE_PROJECT_DETAILS;
    payload: any;
}

interface IFetchProjectdetailsAction {
    type: typeof FETCH_PROJECT_DETAILS;
    id: number;
}
interface IFetchInvoicedetailsAction {
    type: typeof FETCH_INVOICE_DETAILS;
    id: number;
}

const updateProjectDetailsAction = (projectObj: any): GlobalActions => ({
    type: UPDATE_PROJECT_DETAILS,
    payload: projectObj,
});

const fetchProjectDetailsAction = (id: number): GlobalActions => ({
    type: FETCH_PROJECT_DETAILS,
    id,
});
const fetchInvoiceDetailsAction = (id: number): GlobalActions => ({
    type: FETCH_INVOICE_DETAILS,
    id,
});

// Root Action Type
type GlobalActions =
    | IUpdateProjectdetailsAction
    | IFetchProjectdetailsAction
    | IUpdateTokenAction
    | IUpdateStoreAction
    | IFetchInvoicedetailsAction
    | IUpdateServiceLevelAction
    | IUpdateServiceIdAction
    | IUpdateServiceDeleteAction
    | IUpdateServiceImageAction;

export {
    updateProjectDetailsAction,
    fetchProjectDetailsAction,
    updateTokensAction,
    updateStoreAction,
    fetchInvoiceDetailsAction,
    updateServiceLevelAction,
    updateServiceIdAction,
    updateServiceDeleteAction,
    updateServiceImageAction,
};

export type { GlobalActions, IFetchProjectdetailsAction };
