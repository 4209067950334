import { acceptableImageFormat } from '../constants/FilterDataValues';
import { errorMsg } from '../constants/messages';

const MakesFormData = [
    {
        title: 'Makes Details',
        name: 'title',
        label: 'Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'imageUrl',
        label: 'Icon Image',
        fieldType: {
            name: 'uploadFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { MakesFormData };
