import { Form, FormInstance } from 'antd';

import { InputProps } from '../TextInput';
import UploadImage from './UploadImageOrVideo';

interface FileInputProps extends InputProps {
    form: FormInstance;
}

const FileInput = (props: FileInputProps) => {
    const { formInput, index, form } = props;

    const fieldValue = form.getFieldValue(formInput.name);

    return (
        <Form.Item
            key={index}
            className='upload text'
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            labelAlign='left'
            colon={formInput.required}
            name={formInput.name}
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
            ]}>
            {formInput.labelParagraph ? <div className='label-paragraph'> {formInput.labelParagraph} </div> : null}

            <UploadImage name={formInput.name} fieldValue={fieldValue} form={form} fieldType={formInput.fieldType} />
        </Form.Item>
    );
};

export default FileInput;
