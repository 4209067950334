import { acceptableImageFormat } from '../constants/FilterDataValues';
import { errorMsg } from '../constants/messages';
const paymentListingData = [
    {
        name: 'paymentId',
        label: 'paymentId',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'paymentId',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'garageName',
        label: 'Garage Name',
        fieldType: {
            name: 'number',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Garage Name',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'contactPersonPhone',
        label: 'Contact Person',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Contact Person',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'contactPersonEmail',
        label: 'Contact Email',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Contact Email',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'garageAddress',
        label: 'Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Address',
            enableSort: false,
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'paymentPending',
        label: 'Payment Pending (QAR)',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Payment Pending (QAR)',
            enableSort: false,
            allowAddRecordClick: false,
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'paymentStatus',
        label: 'Payment Status',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Payment Status',
            columnType: 'toggle',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'garageLogo',
        label: 'Icon Image',
        fieldType: {
            name: 'uploadFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
];
export { paymentListingData };
