// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; }

.garageBlacklistModal {
  position: absolute;
  left: 40%;
  top: 30%;
  padding: 30px 50px;
  background-color: #f8f5f5;
  border: 1px solid #919090;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.confirmCard {
  display: flex;
  justify-content: center; }

.confirmButtons {
  display: flex;
  padding: 20px 20px 0; }
  .confirmButtons .confirmBtn {
    display: block;
    margin: 10px;
    background-color: #0c0b0b;
    color: #fff;
    border-radius: 5px; }
`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationModal/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa,EAAA;;AAGjB;EACI,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,kBAAkB;EAClB,yBAAoC;EACpC,yBAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;;AAGvB;EACI,aAAa;EACb,uBAAuB,EAAA;;AAG3B;EACI,aAAa;EACb,oBAAoB,EAAA;EAFxB;IAKQ,cAAc;IACd,YAAY;IACZ,yBAAiC;IACjC,WAAW;IACX,kBAAkB,EAAA","sourcesContent":[".modalOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n.garageBlacklistModal {\n    position: absolute;\n    left: 40%;\n    top: 30%;\n    padding: 30px 50px;\n    background-color: rgb(248, 245, 245);\n    border: 1px solid rgb(145, 144, 144);\n    border-radius: 5px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.confirmCard {\n    display: flex;\n    justify-content: center;\n}\n\n.confirmButtons {\n    display: flex;\n    padding: 20px 20px 0;\n\n    .confirmBtn {\n        display: block;\n        margin: 10px;\n        background-color: rgb(12, 11, 11);\n        color: #fff;\n        border-radius: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
