import React from 'react';

import { PlusSquareOutlined } from '@ant-design/icons';
import { Image } from 'antd';

import NoImage from '../../../assets/png/no-image.jpeg';

import './style.scss';

interface ImageCardProps {
    title?: string;
    image?: string;
    addComment: () => void;
}

const ImageCard: React.FC<ImageCardProps> = (props) => {
    const { image, title, addComment } = props;

    let pdfSrc = '';
    if (image?.slice(image.length - 3, image.length) === 'pdf') {
        pdfSrc = image;
    }

    return (
        <div className='imgCardContainer'>
            <div className='titlePlusIcon' onClick={addComment}>
                <div className='cardTitle'>{title}</div>
                <div className='cardIcons'>
                    <PlusSquareOutlined />
                </div>
            </div>
            <div className='imageArea'>
                {pdfSrc ? (
                    <object data={pdfSrc} type='application/pdf' width='240px' height='200px'></object>
                ) : (
                    <Image
                        src={image ? image : NoImage}
                        alt='img'
                        style={{ width: '240px', height: '200px', objectFit: 'cover' }}
                    />
                )}
            </div>
        </div>
    );
};

export default ImageCard;
