// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categoryDropContainer {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .categoryDropContainer #deleteIcon {
    height: 32px; }
`, "",{"version":3,"sources":["webpack://./src/components/ServiceCategory/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;EALvB;IAQQ,YAAY,EAAA","sourcesContent":[".categoryDropContainer{\n    width: 100%;\n    height: 50px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    #deleteIcon{\n        height: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
