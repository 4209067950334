import { errorMsg } from '../constants/messages';

const CouponsFormData = [
    {
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'couponCode',
        label: 'Coupon code (not case sensitive)',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Coupon Code', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'discount',
        label: 'Discount',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Discount', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'usesPerUser',
        label: 'Uses Per User',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Uses Per User', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'validFrom',
        label: 'Valid From',
        fieldType: {
            name: 'date',
            format: 'YYYY-MM-DD',
            specificDate: true,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Valid From', allowAddRecordClick: true, columnType: 'dateAndTime' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'validTo',
        label: 'Valid To',
        fieldType: {
            name: 'date',
            format: 'YYYY-MM-DD',
            specificDate: true,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Valid To', allowAddRecordClick: true, columnType: 'dateAndTime' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'discountType',
        label: 'Discount Type',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Fixed', value: 'fixed' },
                { name: 'Percentage', value: 'percentage' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Discount Type', columnType: 'discountType' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'status',
        label: 'Status',
        placeholder: 'Click here to select status',
        fieldType: {
            name: 'select',
            options: [
                { name: 'YES', value: true },
                { name: 'NO', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Status',
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { CouponsFormData };
