const allowTableShort = (enableSort: boolean, tableKey: string) => {
    if (!enableSort) {
        return false;
    }
    if (enableSort && tableKey) {
        return {
            compare: (a: any, b: any) => a[tableKey] - b[tableKey],
        };
    }
};

export default allowTableShort;
