import { errorMsg } from '../constants/messages';

const ReferralCouponFormData = [
    {
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textArea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'couponCode',
        label: 'Coupon code Not case sensitive',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Coupon Code', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'status',
        label: 'Status',
        placeholder: 'Click here to select status',
        fieldType: {
            name: 'select',
            options: [
                { name: 'YES', value: true },
                { name: 'NO', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Status',
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'discount',
        label: 'Discount',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Discount', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'validity',
        label: 'Validity',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Validity', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'couponTarget',
        label: 'Coupon Target',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Referer', value: 'referer' },
                { name: 'Referee', value: 'referee' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Coupon Target', columnType: 'referralDiscountType' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { ReferralCouponFormData };
