// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBoxContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box; }
  .searchBoxContainer .searchBox {
    border-radius: 50px;
    padding: 2px 9px !important;
    background: #f0f4f7;
    border: none;
    outline: none;
    color: #637f92;
    font-size: 16px;
    box-sizing: border-box;
    width: 240px; }
    .searchBoxContainer .searchBox input {
      color: #637f92;
      background: #f0f4f7; }
    .searchBoxContainer .searchBox .ant-input::placeholder {
      color: #637f92; }
    .searchBoxContainer .searchBox:focus {
      border-color: #637f92 !important;
      z-index: 5; }
    .searchBoxContainer .searchBox:focus-within {
      border-color: #637f92;
      box-shadow: 0 0 0 1px #637f92; }
    .searchBoxContainer .searchBox:hover {
      border: #36a0f7; }
    .searchBoxContainer .searchBox .searchOutline SVG {
      font-size: 18px; }

::placeholder {
  color: #637f92; }

:-ms-input-placeholder {
  color: #637f92; }

::-ms-input-placeholder {
  color: #637f92; }
`, "",{"version":3,"sources":["webpack://./src/components/Search/search.scss"],"names":[],"mappings":"AAIA;EACI,aAAa;EACb,mBAAmB;EACnB,sBAAsB,EAAA;EAH1B;IAMQ,mBAAmB;IACnB,2BAA2B;IAC3B,mBAXkB;IAYlB,YAAY;IACZ,aAAa;IACb,cAfY;IAgBZ,eAAe;IACf,sBAAsB;IACtB,YAAY,EAAA;IAdpB;MAiBY,cArBQ;MAsBR,mBArBc,EAAA;IAG1B;MAsBY,cA1BQ,EAAA;IAIpB;MA0BY,gCAAoC;MAEpC,UAAU,EAAA;IA5BtB;MAgCY,qBApCQ;MAqCR,6BArCQ,EAAA;IAIpB;MAqCY,eAvCU,EAAA;IAEtB;MA0CgB,eAAe,EAAA;;AAM/B;EACI,cArDgB,EAAA;;AAwDpB;EACI,cAzDgB,EAAA;;AA4DpB;EACI,cA7DgB,EAAA","sourcesContent":["$text_color: #637f92;\n$background_color: #f0f4f7;\n$colorPrimary: #36a0f7;\n\n.searchBoxContainer {\n    display: flex;\n    align-items: center;\n    box-sizing: border-box;\n\n    .searchBox {\n        border-radius: 50px;\n        padding: 2px 9px !important;\n        background: $background_color;\n        border: none;\n        outline: none;\n        color: $text_color;\n        font-size: 16px;\n        box-sizing: border-box;\n        width: 240px;\n\n        input {\n            color: $text_color;\n            background: $background_color;\n        }\n\n        .ant-input::placeholder {\n            color: $text_color;\n        }\n\n        &:focus {\n            border-color: $text_color !important;\n            //box-shadow: 0 0 0 2px $colorPrimary !important;\n            z-index: 5;\n        }\n\n        &:focus-within {\n            border-color: $text_color;\n            box-shadow: 0 0 0 1px $text_color;\n        }\n\n        &:hover {\n            border: $colorPrimary;\n        }\n\n        .searchOutline {\n            SVG {\n                font-size: 18px;\n            }\n        }\n    }\n}\n\n::placeholder {\n    color: $text_color;\n}\n\n:-ms-input-placeholder {\n    color: $text_color;\n}\n\n::-ms-input-placeholder {\n    color: $text_color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
