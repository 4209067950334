import { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { FormInstance } from 'antd';

/**
 * It is custom hook which can be used to apply drag and drop feature on a collection of objects
 * @param form is the form instance
 * @param index is the position of the draggable item among a list of draggable items
 * @param formInput contains fields relted to the form field such as name of the form field, type of input etc.
 * @param setDragDropValues is a functions which sets the modified array after the drag operation
 * @returns a ref which is to be used on the node that we want to make draggable
 */
const useDragDrop = (form: FormInstance, index: number, formInput: any, setDragDropValues?: any) => {
    const ref = useRef(null);

    // eslint-disable-next-line no-unused-vars
    const [{ isOver }, drop] = useDrop({
        accept: 'tag',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        // eslint-disable-next-line no-unused-vars
        drop: (item: any, monitor) => {
            const prevFields = form.getFieldValue(formInput.name);

            const getSlices = (smallIndex: number, largeIndex: number, addOne: boolean) => {
                const initialSlice = prevFields.slice(0, smallIndex);
                const middleSlice = addOne
                    ? prevFields.slice(smallIndex + 1, largeIndex + 1)
                    : prevFields.slice(smallIndex, largeIndex);
                const finalSlice = prevFields.slice(largeIndex + 1);
                return [initialSlice, middleSlice, finalSlice];
            };

            if (item.index > index) {
                const [initialSlice, middleSlice, finalSlice] = getSlices(index, item.index, false);
                const updatedFieldValue = {
                    [formInput.name]: [...initialSlice, prevFields[item.index], ...middleSlice, ...finalSlice],
                };
                form.setFieldsValue(updatedFieldValue);
                setDragDropValues?.(updatedFieldValue);
            }
            if (item.index < index) {
                const [initialSlice, middleSlice, finalSlice] = getSlices(item.index, index, true);
                const updatedFieldValue = {
                    [formInput.name]: [...initialSlice, ...middleSlice, prevFields[item.index], ...finalSlice],
                };
                form.setFieldsValue(updatedFieldValue);
                setDragDropValues?.(updatedFieldValue);
            }
        },
    });

    // eslint-disable-next-line no-unused-vars
    const [{ isDragging }, drag] = useDrag({
        type: 'tag',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drop(drag(ref));

    return ref;
};

export default useDragDrop;
