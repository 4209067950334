import { errorMsg } from '../constants/messages';

const userListFormData = [
    {
        title: 'User Details',
        name: 'id',
        label: 'id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'Name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', columnType: 'userName', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'firstName',
        label: 'First Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'First Name' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'lastName',
        label: 'Last Name',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Last Name' },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'bookings',
        label: 'Number of Bookings',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Number of bookings' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'customerVehicles',
        label: 'Number of customer vehicles',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Number of customer vehicles' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'phone',
        label: 'Contact Detail',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact Detail' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
        readonly: false,
    },
    {
        name: 'warranties',
        label: 'Warranties',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Warranties' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'addresses',
        label: 'Number of Addresses',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Number of Addresses' },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        title: 'Addresses Details',
        name: 'addresses',
        label: 'Address',
        fieldType: {
            name: 'table',
            subfieldType: 'text',
            formFields: [
                {
                    name: 'id',
                    label: 'Address Id',
                    fieldType: 'text',
                    required: true,
                    message: 'required',
                },
                {
                    name: 'name',
                    label: 'Name',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
                {
                    name: 'addressLine1',
                    label: 'Address Line 1',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
                {
                    name: 'addressLine2',
                    label: 'Address Line 2',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
                {
                    name: 'city',
                    label: 'City',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
                {
                    name: 'state',
                    label: 'State',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
                {
                    name: 'country',
                    label: 'Country',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
                {
                    name: 'type',
                    label: 'Type',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
                {
                    name: 'countryCode',
                    label: 'Country Code',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
                {
                    name: 'zip',
                    label: 'ZIP',
                    fieldType: 'text',
                    required: false,
                    message: '',
                },
            ],
        },
        readonly: false,
        errorMsg: 'required',
        isTableHeader: false,
        isAccordian: true,
    },
    {
        title: 'Customer Vehicles Details',
        name: 'customerVehicles',
        label: 'Customer Vehicles',
        fieldType: {
            name: 'table',
            subfieldType: 'text',
            formFields: [
                {
                    name: 'id',
                    label: 'Vehicles Id',
                    fieldType: 'text',
                    required: true,
                },
                {
                    name: 'make',
                    label: 'Make',
                    fieldType: 'text',
                    required: false,
                },
                {
                    name: 'model',
                    label: 'Model',
                    fieldType: 'text',
                    required: false,
                },
                {
                    name: 'fuelType',
                    label: 'Fuel Type',
                    fieldType: 'text',
                    required: false,
                },
            ],
        },
        readonly: false,
        errorMsg: 'required',
        isTableHeader: false,
        isAccordian: true,
    },
    {
        title: 'Bookings Details',
        name: 'bookings',
        label: 'Bookings',
        fieldType: {
            name: 'table',
            subfieldType: 'text',
            formFields: [
                {
                    name: 'id',
                    label: 'Booking Id',
                    fieldType: 'text',
                    required: true,
                },
                {
                    name: 'veiclleMake',
                    label: 'Vehicle Make',
                    fieldType: 'text',
                    required: false,
                },
                {
                    name: 'veiclleModel',
                    label: 'Vehicle Model',
                    fieldType: 'text',
                    required: false,
                },
                {
                    name: 'amountPaid',
                    label: 'Amount Paid (QAR)',
                    fieldType: 'text',
                    required: false,
                },
                {
                    name: 'service',
                    label: 'Service',
                    fieldType: 'service',
                    required: false,
                },
                {
                    name: 'deliveryDate',
                    label: 'Delivery Date',
                    fieldType: 'text',
                    required: false,
                },
            ],
        },
        readonly: false,
        errorMsg: 'required',
        isTableHeader: false,
        isAccordian: true,
    },
    {
        title: 'Warranties Details',
        name: 'warranties',
        label: 'Warranties',
        fieldType: {
            name: 'table',
            subfieldType: 'text',
            formFields: [
                {
                    name: 'id',
                    label: 'Warranties Id',
                    fieldType: 'text',
                    required: true,
                },
                {
                    name: 'veiclleMake',
                    label: 'Vehicle Make',
                    fieldType: 'text',
                    required: false,
                },
                {
                    name: 'veiclleModel',
                    label: 'Vehicle Model',
                    fieldType: 'text',
                    required: false,
                },
                {
                    name: 'service',
                    label: 'Service',
                    fieldType: 'service',
                    required: false,
                },
                {
                    name: 'deliveryDate',
                    label: 'Delivery Date',
                    fieldType: 'text',
                    required: false,
                },
            ],
        },
        readonly: false,
        errorMsg: 'required',
        isTableHeader: false,
        isAccordian: true,
    },
];

export { userListFormData };
