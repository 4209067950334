// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.regularServiceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  border: 1px solid #979696;
  margin: auto;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #eeeded; }
  .regularServiceContainer .regularServiceTitle {
    width: 40%;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #979696;
    padding: 20px; }
  .regularServiceContainer .regularServiceImgCards {
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin: 10px; }
    .regularServiceContainer .regularServiceImgCards .imageCard {
      display: flex;
      justify-content: center;
      align-items: center; }
      .regularServiceContainer .regularServiceImgCards .imageCard .serviceImage img {
        height: 60px;
        width: 60px;
        object-fit: cover; }
      .regularServiceContainer .regularServiceImgCards .imageCard .subCategoryTitle {
        font-size: 18px;
        padding: 10px;
        margin-bottom: 0;
        font-weight: 600;
        color: #7c7b7b; }
`, "",{"version":3,"sources":["webpack://./src/components/GarageServices/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU;EACV,yBAAoC;EACpC,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,yBAAoC,EAAA;EATxC;IAYQ,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,+BAA0C;IAC1C,aAAa,EAAA;EAnBrB;IAuBQ,UAAU;IACV,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,aAAa;IACb,YAAY,EAAA;IA5BpB;MA+BY,aAAa;MACb,uBAAuB;MACvB,mBAAmB,EAAA;MAjC/B;QAqCoB,YAAY;QACZ,WAAW;QACX,iBAAiB,EAAA;MAvCrC;QA4CgB,eAAe;QACf,aAAa;QACb,gBAAgB;QAChB,gBAAgB;QAChB,cAAyB,EAAA","sourcesContent":[".regularServiceContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 90%;\n    border: 1px solid rgb(151, 150, 150);\n    margin: auto;\n    margin-bottom: 10px;\n    padding: 20px;\n    background-color: rgb(238, 237, 237);\n\n    .regularServiceTitle {\n        width: 40%;\n        font-size: 30px;\n        font-weight: bold;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        border-right: 1px solid rgb(151, 150, 150);\n        padding: 20px;\n    }\n\n    .regularServiceImgCards {\n        width: 60%;\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n        padding: 10px;\n        margin: 10px;\n\n        .imageCard {\n            display: flex;\n            justify-content: center;\n            align-items: center;\n\n            .serviceImage {\n                img {\n                    height: 60px;\n                    width: 60px;\n                    object-fit: cover;\n                }\n            }\n\n            .subCategoryTitle {\n                font-size: 18px;\n                padding: 10px;\n                margin-bottom: 0;\n                font-weight: 600;\n                color: rgb(124, 123, 123);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
