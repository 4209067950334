import { errorMsg } from '../constants/messages';

const thirdPartyChargesListData = [
    {
        name: 'charge',
        label: 'Charge',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Charge', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'currency',
        label: 'Currency',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Currency', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'chargeType',
        label: 'Charge Type',
        fieldType: {
            name: 'select',
            options: [
                { name: 'FIXED', value: 'fixed' },
                { name: 'PER_KM', value: 'km' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Charge Type',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { thirdPartyChargesListData };
