// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageCards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 10px; }

.branch {
  font-size: 18px;
  font-weight: bold;
  padding: 20px; }

.fieldCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 15px; }

.rejectAcceptBtn {
  margin-right: 30px;
  display: flex;
  justify-content: space-between; }
  .rejectAcceptBtn .ant-btn {
    max-width: 250px;
    width: 100%; }
  .rejectAcceptBtn .garageApproveBtn {
    display: flex;
    column-gap: 10px;
    max-width: 250px;
    width: 100%; }
  .rejectAcceptBtn .backToGarageList {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    padding-left: 30px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    color: green; }
  .rejectAcceptBtn #acceptBtn {
    border-color: #aaf834;
    color: #679c16; }
`, "",{"version":3,"sources":["webpack://./src/components/GarageDetail/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;EACf,aAAa,EAAA;;AAGjB;EACI,eAAe;EACf,iBAAiB;EACjB,aAAa,EAAA;;AAGjB;EACI,aAAa;EACb,qCAAqC;EACrC,iBAAiB,EAAA;;AAGrB;EACI,kBAAkB;EAClB,aAAa;EACb,8BAA8B,EAAA;EAHlC;IAMQ,gBAAgB;IAChB,WAAW,EAAA;EAPnB;IAWQ,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,WAAW,EAAA;EAdnB;IAkBQ,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,YAAY,EAAA;EA1BpB;IA8BQ,qBAA+B;IAC/B,cAAwB,EAAA","sourcesContent":[".imageCards {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    flex-wrap: wrap;\n    row-gap: 10px;\n}\n\n.branch {\n    font-size: 18px;\n    font-weight: bold;\n    padding: 20px;\n}\n\n.fieldCards {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    margin-left: 15px;\n}\n\n.rejectAcceptBtn {\n    margin-right: 30px;\n    display: flex;\n    justify-content: space-between;\n\n    .ant-btn {\n        max-width: 250px;\n        width: 100%;\n    }\n\n    .garageApproveBtn {\n        display: flex;\n        column-gap: 10px;\n        max-width: 250px;\n        width: 100%;\n    }\n\n    .backToGarageList {\n        display: flex;\n        flex-direction: row;\n        column-gap: 10px;\n        align-items: center;\n        padding-left: 30px;\n        cursor: pointer;\n        font-size: 20px;\n        font-weight: 600;\n        color: green;\n    }\n\n    #acceptBtn {\n        border-color: rgb(170, 248, 52);\n        color: rgb(103, 156, 22);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
