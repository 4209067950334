import { errorMsg } from '../constants/messages';

const ContactInfoPageFormData = [
    {
        title: 'Contact Info Details',
        name: 'email',
        label: 'E-mail',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'E-mail', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'contactNumbers',
        label: 'Contact Numbers',
        fieldType: {
            name: 'multipleTextInput',
            minCount: 1,
            maxCount: 3,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact Numbers', columnType: 'contactNumbersMultiple' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'facebook',
        label: 'Facebook Links',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Facebook Links', columnType: 'facebookLink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'twitter',
        label: 'Twitter Links',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Twitter Links', columnType: 'twitterLink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { ContactInfoPageFormData };
