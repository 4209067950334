/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Button, Select, Upload, UploadFile, UploadProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import ModalComponent from '../ModalComponent';
import ServiceAddLevel from '../ServiceAddLevel';
import callApi from '../../Api/config';
import { updateServiceDeleteAction, updateServiceIdAction, updateServiceImageAction } from '../../pages/actions';
import { BASE_API_URL } from '../../env';
import { getToken } from '../../service/CookieManager';
import { SERVICE_CAR_MODEL_ENTITY, SERVICE_SUB_CATEGORY_ENTITY, UPLOAD_PHOTO_ENTITY } from '../../constants/EntityName';

import './style.scss';

let token = getToken(`${BASE_API_URL}/utils/fileUpload`);

const ServiceSubCategory = ({ categoryId, index }: any) => {
    const [isModal, setIsModal] = useState<boolean>(false);
    const [showLevelData, setShowLevelData] = useState<boolean>(false);

    const [subCategoryOptionData, setSubCategoryOptionData] = useState([]);

    const [carDataOption, setCarDataOption] = useState([]);
    const [editLevelId, setEditLevelId] = useState<any>('');

    const { globalReducer }: any = useSelector((state) => state);
    const prevStateData = globalReducer.serviceIdData;
    const prevStateLevelData = globalReducer.levelData.filter((item: any) => item.index === index);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [deletedIndex, setDeletedIndex] = useState<number[]>([]);

    const dispatch = useDispatch();

    // fetch sub-category options and set it in dropdown
    const fetchSubCategoryOptionData = async () => {
        try {
            const res = await callApi(`${SERVICE_SUB_CATEGORY_ENTITY}/${categoryId}`, 'GET');
            const data = res?.data?.data?.map((item: any) => ({ id: item.id, title: item.title }));

            setSubCategoryOptionData(data);
        } catch (error) {
            console.error(`Error fetching sub-categories for category ${categoryId}:`, error);
        }
    };

    // fetch car model options and set it in dropdown
    const fetchCarOptionData = async () => {
        try {
            const res = await callApi(SERVICE_CAR_MODEL_ENTITY, 'GET');

            const extractCarModelPairs = (data: any) => {
                const result: any = [];

                data.forEach((car: any) => {
                    const carName = car.title;

                    car.models.forEach((model: any) => {
                        const modelName = model.title;
                        const modelId = model.id;
                        result.push({ carName, modelName, modelId });
                    });
                });

                return result;
            };

            const dataCar = extractCarModelPairs(res?.data?.data);
            setCarDataOption(dataCar);
        } catch (error) {
            console.error('Error fetching car makes:', error);
        }
    };

    // execute on first render to fetch sub-category and car-model drop-down option data
    useEffect(() => {
        fetchSubCategoryOptionData();
        fetchCarOptionData();
        token = getToken(`${BASE_API_URL}/utils/fileUpload`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleModalClick = (levelId: any) => {
        setEditLevelId(levelId);
        setIsModal(true);
    };

    const onAddLevel = (levelDataPermit: any, modelOpenPermit: any) => {
        setShowLevelData(levelDataPermit);
        setIsModal(modelOpenPermit);
    };

    // extract sub-category Id and title to save in redux-store
    const handleSubCategorySelect = (subCategoryValue: any) => {
        const subCategoryId = subCategoryValue.split('-')[0];
        const subCategoryTitle = subCategoryValue.split('-')[1];
        dispatch(
            updateServiceIdAction({
                ...prevStateData,
                ['subCategoryId' + '_' + index]: subCategoryId,
                ['subCategoryTitle' + '_' + index]: subCategoryTitle,
            }),
        );
    };

    // save selected car model-id to redux-store
    const handleCarModelSelect = (modelIDList: any) => {
        dispatch(
            updateServiceIdAction({
                ...prevStateData,
                ['modelId' + '_' + index]: modelIDList,
            }),
        );
    };

    // on level delete, to obsolete the level data
    const handleDeleteLevel = (id: any) => {
        const filteredData = prevStateLevelData.filter((item: any) => item.labelId !== id);
        dispatch(updateServiceDeleteAction(filteredData));
    };

    // uploaded image preview
    const handlePreview = async (file: UploadFile) => {
        if (file?.response?.data?.url) {
            setPreviewImage(file?.response?.data?.url);
            setPreviewOpen(true);
            setPreviewTitle(file?.name);
        } else {
            console.error('File URL or preview not available.');
        }
    };

    // upload image and save it on redux-store
    const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList, file: UploadFile }) => {
        setFileList(newFileList);

        if (newFileList[0]?.status === 'done') {
            const imageUrl = await UploadFile?.response?.data?.url;
            if (!imageUrl) return;

            dispatch(updateServiceImageAction([imageUrl]));
        }
    };

    // delete the sub-category and remove the corresponding sub-category redux-store data
    const handleDeleteSubCategory = () => {
        const deleteIndex = globalReducer.serviceIdData['index_' + index];
        dispatch(
            updateServiceIdAction({
                ...prevStateData,
                deleteIndex,
            }),
        );
        setDeletedIndex((prev) => [...prev, deleteIndex]);
    };

    return !deletedIndex.includes(index) ? (
        <div className='categorySubsection'>
            <div className='categorySubsectionHeadRow'>
                <div className='categorySubsectionDrop'>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder='Select sub-category'
                        optionFilterProp='children'
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={subCategoryOptionData.map((item: any) => ({
                            value: item.id + '-' + item.title,
                            label: item.title,
                        }))}
                        onSelect={handleSubCategorySelect}
                    />

                    <Select
                        mode='multiple'
                        style={{ width: 200 }}
                        placeholder='Select car model'
                        options={carDataOption.map((item: any) => ({
                            value: item.modelId,
                            label: item.modelName,
                        }))}
                        onChange={handleCarModelSelect}
                    />
                </div>

                <div className='uploadImage'>
                    {/* Use this code to upload images */}
                    <Upload
                        listType='picture-card'
                        action={`${BASE_API_URL}/${UPLOAD_PHOTO_ENTITY}`}
                        headers={{ Authorization: `Bearer ${token}` }}
                        onPreview={handlePreview}
                        onChange={handleChange}>
                        {fileList.length >= 1 ? null : (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Click to upload photo</div>
                            </div>
                        )}
                    </Upload>
                </div>
                <DeleteOutlined onClick={handleDeleteSubCategory} />
            </div>
            <div className='categorySubsectionAddedLevelRow'>
                <p className='addLevelTitle'>Added Levels</p>
                <div className='tableContainer'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Level Title</th>
                                <th>Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {showLevelData && (
                                <>
                                    {prevStateLevelData.map((level: any, index: any) => (
                                        <tr key={index} className='levelContainer'>
                                            <td className='levelName'>{level.label}</td>
                                            <td className='levelPrice'>{level.priceValue}</td>
                                            <td className='editDeleteBtn'>
                                                <Button
                                                    className='deleteLevel'
                                                    onClick={() => handleDeleteLevel(level.labelId)}>
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>

                <Button className='addLevelBtn' onClick={() => handleModalClick(undefined)}>
                    + Add Levels
                </Button>
            </div>
            <ModalComponent openModal={isModal} closeModal={() => setIsModal(false)}>
                <div className='wrapper'>
                    <ServiceAddLevel onAddLevel={onAddLevel} onEditMode={editLevelId} index={index} />
                </div>
            </ModalComponent>
        </div>
    ) : null;
};

export default ServiceSubCategory;
