/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Button, Select } from 'antd';
import { useEffect, useState } from 'react';

import callApi from '../../Api/config';
import { SERVICE_CATEGORY_ENTITY } from '../../constants/EntityName';

import './style.scss';

const ServiceCategory = ({ handleSubCategory }: any) => {
    const [categoryOptionData, setCategoryOptionData] = useState([]);
    const [isDropDownDisabled, setIsDropDownDisabled] = useState(false);

    // fetch categories and set them as drop-down select options
    useEffect(() => {
        const fetchOptionData = async () => {
            try {
                const res = await callApi(SERVICE_CATEGORY_ENTITY, 'GET');
                const data = res?.data?.data?.map((item: any) => ({ id: item.id, title: item.title }));
                setCategoryOptionData(data);
            } catch (error) {
                console.error('Error fetching service categories:', error);
            }
        };
        fetchOptionData();
    }, []);

    const handleSelectChange = (selectedValue: any) => {
        setIsDropDownDisabled(true);
        handleSubCategory(selectedValue.split('-')[0], selectedValue.split('-')[1]);
    };

    return (
        <div className='categoryDropContainer'>
            <div>
                <Select
                    style={{ width: 200 }}
                    placeholder='Select Category'
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={categoryOptionData.map((item: any) => ({
                        value: item.id + '-' + item.title,
                        label: item.title,
                    }))}
                    onSelect={handleSelectChange}
                    disabled={isDropDownDisabled}
                />
                <Button
                    onClick={handleSubCategory}
                    style={{ margin: '0 10px' }}
                    disabled={!isDropDownDisabled ? true : false}>
                    + Add Subcategory
                </Button>
            </div>
        </div>
    );
};

export default ServiceCategory;
