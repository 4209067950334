/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

interface TextEditorProps {
    value?: any;
    setValue?: any;
}

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image'],
            ['clean'],
            ['code-block'],
        ],
    },
    clipboard: { matchVisual: false },
};

const CustomTextEditor = (props: TextEditorProps) => {
    const { value, setValue } = props;

    return <ReactQuill theme='snow' modules={modules} value={value} onChange={setValue} />;
};

export default CustomTextEditor;
