import React, { useEffect, useState } from 'react';
import { Form, Checkbox } from 'antd';

import { InputInterface } from '../utils/forms/FormInterface';

interface CheckboxGroupProps {
    formInput: InputInterface;
    index: number;
    form: any;
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
    const { formInput, index, form } = props;

    const permissionTypes = formInput.fieldType.options;

    const { getFieldValue } = form;
    const value = getFieldValue(formInput.name);
    const length = value.length;

    const [indeterminate, setIndeterminate] = useState(length && length < permissionTypes.length);
    const [checkAll, setCheckAll] = useState(length === permissionTypes.length);

    /**
     * handles the click/Enter on checkbox belonging to a group in multi-checkbox setup
     * @param {string[]} checkedValues the array of currently checked values
     */
    const onChange = (checkedValues: any) => {
        setCheckAll(checkedValues.length === permissionTypes.length);
    };

    const onCheckAllChange = (e: any) => {
        form.setFieldsValue({
            [formInput.name]: e.target.checked ? permissionTypes : [],
        });
        setCheckAll(e.target.checked);
    };

    return (
        <div key={index} className='checkboxGroupContainer'>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}>
                <Form.Item label={<span>{formInput.label}</span>}></Form.Item>

                <Form.Item noStyle>
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                        Complete Accesss
                    </Checkbox>
                </Form.Item>
            </div>

            <Form.Item
                name={formInput.name}
                valuePropName='checked'
                className='text'
                rules={[
                    {
                        required: formInput.required,
                        message: formInput.errorMsg,
                    },
                ]}>
                <CustomCheckboxGroup
                    options={formInput.fieldType.options}
                    name={formInput.name}
                    permissionTypes={permissionTypes}
                    setIndeterminate={setIndeterminate}
                    setCheckAll={setCheckAll}
                    onChange={onChange}
                    form={form}
                />
            </Form.Item>
        </div>
    );
};

export default CheckboxGroup;

interface CustomCheckboxGroupProps {
    options: string[];
    name: any;
    permissionTypes: string[];
    setIndeterminate: React.Dispatch<React.SetStateAction<boolean>>;
    setCheckAll: React.Dispatch<React.SetStateAction<boolean>>;
    onChange: any;
    form: any;
}

const CustomCheckboxGroup = (props: CustomCheckboxGroupProps) => {
    const { options, permissionTypes, name, setIndeterminate, setCheckAll, onChange, form } = props;
    const { getFieldValue } = form;
    const value = getFieldValue(name);

    /**
     * whenever any input in the checkbox group is modified, this useEffect renders the correct state (whether ticked or indeterminate or no styling) of 'Complete Access' checkbox
     */
    useEffect(() => {
        setIndeterminate(!!value.length && value.length < 4);
        setCheckAll(value.length === permissionTypes.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return <Checkbox.Group className='checkbox1' options={options} value={value} onChange={onChange} />;
};
