// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: absolute;
  bottom: 0;
  width: 400px;
  display: flex;
  justify-content: center; }
  .footer .filterModalBtn {
    margin: 10px;
    width: 400px;
    height: 40px; }
  .footer #footApplyBtn {
    background-color: #000;
    color: #fff; }
`, "",{"version":3,"sources":["webpack://./src/components/FilterData/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,aAAa;EACb,uBAAuB,EAAA;EAL3B;IAQQ,YAAY;IACZ,YAAW;IACX,YAAY,EAAA;EAVpB;IAcQ,sBAAsB;IACtB,WAAW,EAAA","sourcesContent":[".footer{\n    position: absolute;\n    bottom: 0;\n    width: 400px;\n    display: flex;\n    justify-content: center;\n\n    .filterModalBtn{\n        margin: 10px;\n        width:400px;\n        height: 40px;\n    }\n\n    #footApplyBtn{\n        background-color: #000;\n        color: #fff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
