// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; }
  .tabLinks .links {
    display: flex;
    flex-direction: row; }
    .tabLinks .links p {
      padding: 10px;
      font-size: 1.3rem;
      text-decoration: none;
      transition: all 0.3s ease; }
      .tabLinks .links p:hover {
        cursor: pointer;
        border-bottom: 2px solid transparent; }
      .tabLinks .links p.active {
        font-weight: bold;
        border-bottom: 2px solid #000; }
  .tabLinks #filter {
    padding: 10px;
    display: flex;
    align-items: center;
    border: 1px solid black;
    cursor: pointer; }
    .tabLinks #filter p {
      margin-bottom: 0px;
      padding: 0 0 0 5px;
      font-size: 15px;
      font-weight: 500; }

.pickUpTitle h2 {
  padding: 15px 0px 0px 30px;
  font-size: 1.6rem;
  font-weight: 600; }

.pickUpActionBar .actionbar {
  display: none; }
`, "",{"version":3,"sources":["webpack://./src/pages/PickUpManagement/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB,EAAA;EAJtB;IAOQ,aAAa;IACb,mBAAmB,EAAA;IAR3B;MAWY,aAAa;MACb,iBAAiB;MACjB,qBAAqB;MACrB,yBAAyB,EAAA;MAdrC;QAiBgB,eAAe;QACf,oCAAoC,EAAA;MAlBpD;QAsBgB,iBAAiB;QACjB,6BAA6B,EAAA;EAvB7C;IA6BQ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe,EAAA;IAjCvB;MAoCY,kBAAkB;MAClB,kBAAkB;MAClB,eAAe;MACf,gBAAgB,EAAA;;AAK5B;EACI,0BAA0B;EAC1B,iBAAiB;EACjB,gBAAgB,EAAA;;AAGpB;EACI,aAAa,EAAA","sourcesContent":[".tabLinks {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 20px;\n\n    .links{\n        display: flex;\n        flex-direction: row;\n\n        p {\n            padding: 10px;\n            font-size: 1.3rem;\n            text-decoration: none;\n            transition: all 0.3s ease;\n    \n            &:hover {\n                cursor: pointer;\n                border-bottom: 2px solid transparent;\n            }\n    \n            &.active {\n                font-weight: bold;\n                border-bottom: 2px solid #000;\n            }\n        }\n    }\n\n    #filter{\n        padding: 10px;\n        display: flex;\n        align-items: center;\n        border: 1px solid black;\n        cursor: pointer;\n\n        p{\n            margin-bottom: 0px;\n            padding: 0 0 0 5px;\n            font-size: 15px;\n            font-weight: 500;\n        }\n    }\n}\n\n.pickUpTitle h2{\n    padding: 15px 0px 0px 30px;\n    font-size: 1.6rem;\n    font-weight: 600;\n}\n\n.pickUpActionBar .actionbar{\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
