import { useHistory } from 'react-router-dom';

import { useState } from 'react';
import { Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

// import EditModal from '../../EditModal';
// import Vectordelete from '../../../assets/svg/Vectordelete.svg';
// import { getTimeSlotComponent } from '../../../pages/Stores/utils';

import { getSlotTimeRange } from './utils';

import './styles.scss';
import DeleteModal from '../../DeleteModal';

interface DragAndDropTableProps {
    key?: any;
    tableData?: any;
    formTemplate: any;
    entityId?: string;
    variantSlug?: string;
}

export const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
};

const DragAndDropTable = (props: DragAndDropTableProps) => {
    const history = useHistory();
    const handleOrdersRouting = (id: any) => {
        history.push(`/orders/${id}`);
    };
    const { entityId } = props;
    // eslint-disable-next-line no-unused-vars
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [selectedRecordDelete, setSelectedRecordDelete] = useState<any>(null);
    // eslint-disable-next-line no-unused-vars
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalVisibleDelete, setModalVisibleDelete] = useState<boolean>(false);

    // const handleCloseModal = () => {
    //     setModalVisible(false);
    // };

    const handleCloseModalDelete = () => {
        setModalVisibleDelete(false);
    };

    const columns = props.formTemplate.fieldType.formFields.map((inputField: any) => ({
        key: inputField.name,
        title: inputField.label,
        dataIndex: inputField.name,
        columnType: inputField.columnType,

        render: (value: any, record: any) => {
            if (typeof value == 'boolean') {
                return value ? 'Yes' : 'No';
            }

            if (inputField.name == 'service') {
                const serviceDetailsList = record.service.map(
                    (serviceDetailsList: any) => serviceDetailsList.serviceDetail,
                );

                const allServiceDetails = serviceDetailsList.join(' , ');

                return allServiceDetails;
            }

            if (inputField.name === 'slotTimeRange') {
                return getSlotTimeRange(record);
            }

            return (
                <>
                    {value}
                    {inputField.name === 'action' && (
                        <EditOutlined
                            onClick={() => {
                                setSelectedRecord(record);
                                setModalVisible(true);
                            }}
                        />
                    )}
                    {inputField.name === 'deleteAction' && (
                        <DeleteOutlined
                            onClick={() => {
                                setSelectedRecordDelete(record);
                                setModalVisibleDelete(true);
                            }}
                        />
                    )}
                    {inputField.name === 'viewOrderAction' && (
                        <EditOutlined onClick={() => handleOrdersRouting(record.id)} />
                    )}

                    {inputField.name === 'deleteAction' && <DeleteOutlined />}
                </>
            );
        },
    }));

    const dataSource = props.tableData?.map((singleRow: any) => ({
        ...singleRow,

        // delete: <img className='table-action' src={Vectordelete} />,
    }));

    // eslint-disable-next-line no-console

    return (
        <div>
            <Table columns={columns} dataSource={dataSource} bordered />
            {/* {selectedRecord && (
                <EditModal
                    visible={modalVisible}
                    record={selectedRecord}
                    onCancel={handleCloseModal}
                    entityId={entityId}
                />
            )} */}
            {selectedRecordDelete && (
                <DeleteModal
                    visible={modalVisibleDelete}
                    record={selectedRecordDelete}
                    onCancel={handleCloseModalDelete}
                    entityId={entityId}
                />
            )}
        </div>
    );
};

export default DragAndDropTable;
