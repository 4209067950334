import { errorMsg } from '../../constants/messages';

const onboardingRequestData = [
    {
        name: 'garageId',
        label: 'Garage Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Garage Id' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'garageName',
        label: 'Garage Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Garage Name', enableSort: false, allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'customerInfo',
        label: 'Customer Info',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Customer Info' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'fullAddress',
        label: 'Address',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Address' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'createdAt',
        label: 'Bookings Served',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Bookings Served' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'pickup',
        label: 'Pickup Slot Limit',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Pickup Slot Limit' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'phone',
        label: 'Actions',
        fieldType: {
            name: 'text',
        },
        placeholder: 'Click here to select an item',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Actions', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,

        readonly: false,
    },
];

export { onboardingRequestData };
