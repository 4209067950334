/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Button } from 'antd';

import { GUIDELINES_ENTITY } from '../../constants/EntityName';
import callApi from '../../Api/config';
import CustomTextEditor from '../../components/CustomTextEditor';
import { toastSuccess } from '../../service/Toast';

import './style.scss';

export enum GuidelineType {
    TERMS_CONDITIONS = 'terms-conditions',
    PRIVACY_POLICY = 'privacy-policy',
    ABOUT_US = 'about-us',
}

const guide = {
    name: '',
    label: '',
    fieldType: 'textEditor',
    errorMsg: '',
    tableMeta: { title: 'Content', enableSort: false, allowAddRecordClick: false, columnType: 'textEditor' },
};

const Guidelines = () => {
    const [currentTab, setCurrentTab] = useState<string>('Privacy Policy');

    const [currentTabData, setCurrentTabData] = useState<string>('');

    const [currentTabId, setCurrentTabId] = useState<string>('');

    const [updatedEditorData, setUpdatedEditorData] = useState<string>(currentTabData);

    const [loadings, setLoadings] = useState<boolean[]>([]);

    const handleEditValue = (d: any) => {
        setCurrentTabData(d);
    };

    const currentTabKey: any = {
        'Privacy Policy': GuidelineType.PRIVACY_POLICY,
        'Terms & Conditions': GuidelineType.TERMS_CONDITIONS,
        'About Us': GuidelineType.ABOUT_US,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await callApi(`${GUIDELINES_ENTITY}/${currentTabKey[currentTab]}`, 'GET');
                setCurrentTabData(res?.data?.data?.content);
                setCurrentTabId(res?.data?.data?.id);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    const enterLoading = (index: number) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });

        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 700);
    };

    const handleUpdate = async () => {
        const updatedData = {
            'content': currentTabData,
            'type': currentTabKey[currentTab],
        };
        try {
            const res = await callApi(`${GUIDELINES_ENTITY}/${currentTabId}`, 'PATCH', updatedData);
            enterLoading(0);
            if (res?.data?.success) {
                setTimeout(() => {
                    toastSuccess('Updated Successfully');
                }, 1200);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    return (
        <div className='guidelineContainer'>
            <div className='headTitle'>
                <h2>Izhal Guidlines</h2>
            </div>
            <div className='tabLinks'>
                <div className='links'>
                    <p
                        id='privacyPolicy'
                        className={currentTab === 'Privacy Policy' ? 'active' : ''}
                        onClick={() => setCurrentTab('Privacy Policy')}>
                        Privacy Policy
                    </p>
                    <p
                        id='termsConditions'
                        className={currentTab === 'Terms & Conditions' ? 'active' : ''}
                        onClick={() => setCurrentTab('Terms & Conditions')}>
                        Terms & Conditions
                    </p>
                    <p
                        id='aboutUs'
                        className={currentTab === 'About Us' ? 'active' : ''}
                        onClick={() => setCurrentTab('About Us')}>
                        About Us
                    </p>
                </div>
            </div>
            {currentTab === 'Privacy Policy' ? (
                <div className='privacyTermTab'>
                    <CustomTextEditor value={currentTabData} setValue={handleEditValue} />
                </div>
            ) : (
                <div className='privacyTermTab'>
                    <CustomTextEditor value={currentTabData} setValue={handleEditValue} />
                </div>
            )}

            <div className='guidelineUpdate'>
                <Button type='primary' loading={loadings[0]} onClick={handleUpdate}>
                    Update
                </Button>
            </div>
        </div>
    );
};

export default Guidelines;
