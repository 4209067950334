/* eslint-disable no-console */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Form, Input } from 'antd';

import { toastError, toastSuccess } from '../../service/Toast';
import { setModulePermissionList, setStoreId, setToken, setThermalPrinterToken } from '../../service/CookieManager';
import { updateTokensAction } from '../actions';
import callApi from '../../Api/config';
import { AppRoutes } from '../../constants/AppConstant';

import IzhalLogo from '../../assets/svg/Group.svg';

import './styles.scss';

// eslint-disable-next-line no-unused-vars
const userTypeOptions = [
    { id: '1', value: 'Customer', name: 'Customer' },
    { id: '2', value: 'admin', name: 'Admin' },
    { id: '3', value: 'Employee', name: 'Employee' },
    { id: '4', value: 'Manager', name: 'Manager' },
];

const LOGIN_URL = '/auth/sign-in';
const VERIFY_OTP_URL = '/auth/otp/verify';

const PageStatus = {
    MobileInput: 'mobileInput',
    OTPInput: 'otpInput',
};

const LoginStatus = {
    Initial: 'initial',
    Progress: 'progress',
    Success: 'success',
    Failure: 'failure',
};

const buttonText = {
    // [PageStatus.MobileInput]: 'Get OTP',
    [PageStatus.MobileInput]: 'Submit',
    [PageStatus.OTPInput]: 'Verify OTP',
};

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [pageStatus, setPageStatus] = useState(PageStatus.MobileInput);
    const [loginStatus, setLoginStatus] = useState(LoginStatus.Initial);
    // const [userType, setUserType] = useState<string>('admin');

    // function getRedirectRoute() {
    //     const modulesList = getModulePermission();
    //     const permissionModuleListArray = modulesList ? JSON.parse(modulesList) : [];
    //     return permissionModuleListArray[0];
    // }

    const onFinish = async (incomingPayload: { phone: string; password: string; userType: string; otp?: string }) => {
        const payload: { phone: string; password: string; userType: string; otp?: string; countryCode: string } = {
            phone: incomingPayload.phone,
            password: incomingPayload.password,
            // userType: userType,
            userType: 'admin',
            countryCode: '91', // Set countryCode to '91' by default
        };

        switch (pageStatus) {
            case PageStatus.MobileInput: {
                setLoginStatus(LoginStatus.Progress);

                const response = await callApi(LOGIN_URL, 'POST', payload);
                setLoginStatus(LoginStatus.Initial);

                if (response?.status && response?.data) {
                    toastSuccess('Login successfully');
                    setToken(response.data.data.accessToken);
                    dispatch(updateTokensAction(response.data.data.accessToken));
                    history.push(AppRoutes.Dashboard);
                    setLoginStatus(LoginStatus.Initial);
                    setPageStatus(PageStatus.OTPInput);
                } else {
                    toastError(response?.data.error || 'Server Error!');
                    setLoginStatus(LoginStatus.Failure);
                }

                break;
            }

            case PageStatus.OTPInput: {
                setLoginStatus(LoginStatus.Progress);

                const response: any = await callApi(VERIFY_OTP_URL, 'POST', payload);
                setLoginStatus(LoginStatus.Initial);

                if (response?.status && response?.data) {
                    const {
                        data: { accessToken, payload, thermalPrinterToken },
                    } = response.data;
                    toastSuccess('OTP verified successfully');
                    setLoginStatus(LoginStatus.Success);
                    setToken(accessToken);
                    setModulePermissionList(payload.modules);
                    dispatch(updateTokensAction(accessToken));
                    history.push('admin/users');
                    setThermalPrinterToken(thermalPrinterToken);
                    const storeId = 'storeId' in payload ? payload.storeId : null;
                    storeId && setStoreId(storeId);
                } else {
                    toastError('Incorrect OTP');
                }
            }
        }
    };

    return (
        <div className='container flex'>
            <div className='logo-container flex align-center justify-center'>
                <div className='flex align-center'></div>
            </div>
            <div className='form-container'>
                <div className='box'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '40px',
                        }}>
                        <img src={IzhalLogo} alt='Logo' style={{ width: '200px', height: '200px' }} />
                    </div>
                    <h3 style={{ fontSize: '52px', fontWeight: '600' }}>Login to Dashboard</h3>
                    <Form
                        name='normal_login'
                        className='login-form'
                        layout='vertical'
                        initialValues={{ remember: true }}
                        onFinish={onFinish}>
                        <Form.Item
                            name='phone'
                            label='Mobile Number'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}>
                            <Input
                                placeholder='Enter your mobile number'
                                disabled={pageStatus === PageStatus.OTPInput && true}
                            />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            label='Password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}>
                            <Input
                                placeholder='Enter your Password'
                                disabled={pageStatus === PageStatus.OTPInput && true}
                            />
                        </Form.Item>
                        {/* <Form.Item label='User Type' required>
                            <Select
                                showSearch
                                placeholder='Click here to select User Type'
                                allowClear
                                value={userType}
                                disabled
                                onChange={setUserType}>
                                {userTypeOptions.map((option) => (
                                    <Select.Option key={option.id} value={option.name}>
                                        {option.value}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item> */}
                        {/* {pageStatus === PageStatus.OTPInput && (
                            <Form.Item
                                name='otp'
                                label='Enter your OTP'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the valid OTP here!',
                                    },
                                ]}>
                                <Input placeholder='Enter the OTP here' />
                            </Form.Item>
                        )} */}

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='login-form-button'
                                block
                                disabled={loginStatus === LoginStatus.Progress}>
                                {buttonText[pageStatus]}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;
