import { errorMsg } from '../constants/messages';

const orderListingData = [
    {
        name: 'orderDetails',
        label: 'Order Details',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Order Details',
        },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
        default: '',
    },
    {
        name: 'customerInfo',
        label: 'Customer info',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Customer info',
        },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
        default: '',
    },
    {
        name: 'vehicleDetails',
        label: 'Vehicle Details',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Vehicle Details',
        },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
        default: '',
    },
    {
        name: 'services',
        label: 'Service Selected',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Service Selected',
            columnType: 'servicesObjectArray',
            allowAddRecordClick: false,
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: true,
        default: '',
    },
    {
        name: 'pickupDetails',
        label: 'Pickup Details',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Pickup Details',
        },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
        default: '',
    },
    {
        name: 'totalPrice',
        label: 'Amount (QAR)',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Amount (QAR)',
        },
        isTableHeader: true,
        isAccordian: false,
        readonly: true,
        default: '',
    },
];

export { orderListingData };
