/* eslint-disable no-console */
import { PlusSquareOutlined } from '@ant-design/icons';
import React from 'react';

import './style.scss';

interface FieldCardProps {
    title?: string;
    placeholder?: string;
    addComment?: () => void;
    copyValue?: any;
}

const FieldCard: React.FC<FieldCardProps> = (props) => {
    const { title, placeholder, addComment, copyValue } = props;

    return (
        <div className='fieldCardContainer' onClick={addComment}>
            <div className='titlePlusIcon'>
                <div className='fieldCardTitle'>{title}</div>
                <PlusSquareOutlined />
            </div>
            <div className='inputArea'>
                <input type='text' placeholder={placeholder ? placeholder : 'NA'} value={copyValue} disabled />
            </div>
        </div>
    );
};

export default FieldCard;
