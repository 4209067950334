// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer {
  background: #f3f3f3; }
  .mainContainer .cardContainer {
    border-radius: 10px;
    margin: 0px 20px; }
    .mainContainer .cardContainer .ant-card-body {
      padding: 20px; }
  .mainContainer .barChart {
    display: grid;
    /* Change from flex to grid */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Adjust grid columns based on your needs */
    gap: 30px;
    border-radius: 10px;
    padding: 25px; }
    .mainContainer .barChart .yearlyChart {
      width: 100%;
      border-radius: 10px;
      background: #ffffff;
      padding: 20px; }
    .mainContainer .barChart .levelEarning {
      width: 100%;
      border-radius: 10px;
      background: #ffffff;
      padding: 15px; }
  .mainContainer .topPerformingGarage {
    border-radius: 10px;
    padding: 25px; }
    .mainContainer .topPerformingGarage .tableList {
      background: #ffffff; }
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/style.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB,EAAA;EADvB;IAIQ,mBAAmB;IACnB,gBAAgB,EAAA;IALxB;MAQY,aAAa,EAAA;EARzB;IAaQ,aAAa;IAAE,6BAAA;IACf,2DAA2D;IAAE,4CAAA;IAC7D,SAAS;IACT,mBAAmB;IACnB,aAAa,EAAA;IAjBrB;MAoBY,WAAW;MACX,mBAAmB;MACnB,mBAAmB;MACnB,aAAa,EAAA;IAvBzB;MA2BY,WAAW;MACX,mBAAmB;MACnB,mBAAmB;MACnB,aAAa,EAAA;EA9BzB;IAmCQ,mBAAmB;IACnB,aAAa,EAAA;IApCrB;MAuCY,mBAAmB,EAAA","sourcesContent":[".mainContainer {\n    background: #f3f3f3;\n\n    .cardContainer {\n        border-radius: 10px;\n        margin: 0px 20px;\n\n        .ant-card-body {\n            padding: 20px;\n        }\n    }\n\n    .barChart {\n        display: grid; /* Change from flex to grid */\n        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust grid columns based on your needs */\n        gap: 30px;\n        border-radius: 10px;\n        padding: 25px;\n\n        .yearlyChart {\n            width: 100%;\n            border-radius: 10px;\n            background: #ffffff;\n            padding: 20px;\n        }\n\n        .levelEarning {\n            width: 100%;\n            border-radius: 10px;\n            background: #ffffff;\n            padding: 15px;\n        }\n    }\n\n    .topPerformingGarage {\n        border-radius: 10px;\n        padding: 25px;\n\n        .tableList {\n            background: #ffffff;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
